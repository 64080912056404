import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import footerLogo from "../../assets/images/Daga_Impex_Logo.png";
import { InstagramOutlined, LinkedinOutlined, WhatsAppOutlined, XOutlined, YoutubeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};


const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const { width, height } = useWindowSize();

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <>
      <footer
        className={`footer-area ${backgroundColorClass ? backgroundColorClass : ""
          } ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
          } ${extraFooterClass ? extraFooterClass : ""} ${spaceLeftClass ? spaceLeftClass : ""
          } ${spaceRightClass ? spaceRightClass : ""}`}
      >
        <div className={`${containerClass ? containerClass : "container"}`}>
          <div className="row">
            <div
              className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                }`}
            >

              <FooterCopyright
                footerLogo="/assets/img/logo/logo.png"
                spaceBottomClass="mb-30"
              />
            </div>
            <div
              className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                }`}
            >
              <div className="footer-widget mb-30 ml-30">
                <div className="footer-title">
                  <h3>ABOUT US</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "/about-us"}>About us</Link>
                    </li>
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "#/"}>
                        Store location
                      </Link>
                    </li>
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "/contact-us"}>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "#/"}>
                        Orders tracking
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                }`}
            >
              <div
                className={`${sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-50"
                  }`}
              >
                <div className="footer-title">
                  <h3>USEFUL LINKS</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "#/"}>Returns</Link>
                    </li>
                    {/* <li>
                      <Link to={process?.env?.PUBLIC_URL + "#/"}>
                        Support Policy
                      </Link>
                    </li> */}
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "#/"}>Size guide</Link>
                    </li>
                    {/* <li>
                      <Link to={process?.env?.PUBLIC_URL + "#/"}>FAQs</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"
                }`}
            >
              <div
                className={`${sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-75"
                  }`}
              >
                <div className="footer-title">
                  <h3>FOLLOW US</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100063524995700"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/dagaimpex.in?igsh=MWYyOGRuMTFid3diOQ=="
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/@dagaimpex-indore?si=xkfvaSHevc-cVJB3"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Youtube
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={`${sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
                }`}
            >
              <FooterNewsletter
                spaceBottomClass="mb-30"
                spaceLeftClass="ml-70"
                sideMenu={sideMenu}
              />
            </div>
          </div>
        </div>
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <i className="fa fa-angle-double-up"></i>
        </button>
      </footer>

      {
        width < 700
          ?
          <>
            <footer
              className="d-flex justify-content-between align-items-center p-2 bg-black text-white"
            >
              <Row gutter={24} className="w-100 footer-container p-2">
                <Col sm={12} lg={8} className="footer-logo">
                  <div>
                    <img
                      alt=""
                      src={footerLogo}
                      width="60px"
                    />
                  </div>
                </Col>

                <Col sm={24} lg={8} className="footer-text">
                  <div className="d-flex justify-content-center align-items-center">
                    © {new Date().getFullYear()} All Rights Reserved
                  </div>
                </Col>

                <Col sm={12} lg={8} className="footer-icons">
                  <div className="d-flex justify-content-end align-items-center">
                    <div>
                      <InstagramOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <YoutubeOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <XOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <LinkedinOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <WhatsAppOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </footer>
          </>
          :
          <>
            <footer
              className="d-flex justify-content-between align-items-center p-2 bg-black text-white"
            >
              <Row gutter={24} className="w-100 align-items-center p-2">
                <Col span={8} className="footer-logo">
                  <Link to="/">
                    <img
                      alt=""
                      src={footerLogo}
                      width="80px"
                    />
                  </Link>
                </Col>

                <Col span={8} className="">
                  <div className="d-flex justify-content-center align-items-center">
                    © {new Date().getFullYear()} All Rights Reserved
                  </div>
                </Col>

                <Col span={8} className="">
                  <div className="d-flex justify-content-end align-items-center">
                    <div>
                      <InstagramOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <YoutubeOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <XOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <LinkedinOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                    <div>
                      <WhatsAppOutlined
                        style={{
                          fontSize: "15px",
                          marginInline: "5px",
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </footer>
          </>
      }

    </>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
