import React, { useState } from "react";
import {
  Button,
  Drawer,
  Row,
  Col,
  Spin,
  Table,
  Tooltip,
  Popconfirm,
  message,
  Tag,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getPurchaseByBarcodeSale } from "../../../redux/actions/purchaseAction";
import { useDispatch, useSelector } from "react-redux";
import {
  checkSalesitemisdeleted,
  getSaleUsingInvoiceId,
  updateSales,
} from "../../../redux/actions/saleAction";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
const UnbillProductBillCreate = ({
  barcodes,
  invoiceId,
  billData,
  recheckVerified,
}) => {
  const dispatch = useDispatch();
  const { Column } = Table;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barcodeData, setBarcodeData] = useState([]);
  const [noFoundBarcodes, setNoFoundBarcodes] = useState([]);
  const [deletAvailable, setDeleteAvailable] = useState([]);

  const showDrawer = () => {
    setOpen(true);
    handleAllbarcodes();
  };

  // Handle Drawer Close
  const onClose = () => {
    setOpen(false);
    setLoading(false);
    setBarcodeData([]);
    setNoFoundBarcodes([]);
    setDeleteAvailable([]);
  };

  // Initial Api Call and getting data for barcode
  const handleAllbarcodes = async () => {
    setLoading(true);
    for (let index = 0; index < barcodes.length; index++) {
      const fetchBarcodeData = await dispatch(
        getPurchaseByBarcodeSale({ barcode: barcodes[index].barcode })
      );
      if (fetchBarcodeData.status === 200) {
        const isDeletedCheck = await dispatch(
          checkSalesitemisdeleted({
            barcode: barcodes[index].barcode,
            invoiceId: invoiceId[0].invoiceId,
          })
        );

        if (isDeletedCheck.data.status) {
          setBarcodeData((prev) => [
            ...prev,
            {
              ...fetchBarcodeData.data,
              // saleQty: barcodes[index].quantity,
              saleQty: barcodes[index].quantity,
              recycleAvailable: true,
            },
          ]);
        } else {
          setBarcodeData((prev) => [
            ...prev,
            {
              ...fetchBarcodeData.data,
              // saleQty: barcodes[index].quantity,
              saleQty: Number(
                fetchBarcodeData?.data.MOQ * barcodes[index].quantity
              ),
              recycleAvailable: false,
            },
          ]);
        }
      } else {
        setNoFoundBarcodes([...noFoundBarcodes, barcodes[index]]);
      }
    }
    setLoading(false);
  };

  // Increase Qty
  const onAddQnty = (barcode, data) => {
    // Find the item in barcodeData that matches the barcode
    const itemIndex = barcodeData.findIndex((item) => item.barcode === barcode);

    // If the item is not found, you can handle it accordingly
    if (itemIndex === -1) {
      return;
    }

    const item = barcodeData[itemIndex];

    // Check the conditions
    if (item.totalPurchaseValue <= 0) {
      message.error("Out Of Stock!");
      return;
    }

    if (item.totalPurchaseValue > item.saleQty) {
      // Update the saleQty
      const updatedItem = {
        ...item,
        saleQty: item.saleQty + data.MOQ, // Assuming data.MOQ is the quantity to add
      };

      // Create a new array with the updated item
      const updatedBarcodeData = [
        ...barcodeData.slice(0, itemIndex),
        updatedItem,
        ...barcodeData.slice(itemIndex + 1),
      ];

      // Update the state
      setBarcodeData(updatedBarcodeData);
    } else {
      message.error("Out Of Stock!");
    }
  };

  // Remove Qty
  const onRemoveQnty = (barcode, data) => {
    // Find the item in barcodeData that matches the barcode
    const itemIndex = barcodeData.findIndex((item) => item.barcode === barcode);

    // If the item is not found, handle it accordingly
    if (itemIndex === -1) {
      return;
    }

    const item = barcodeData[itemIndex];

    // Check if the current saleQty is greater than MOQ
    if (item.saleQty > data.MOQ) {
      // Update the saleQty
      const updatedItem = {
        ...item,
        saleQty: item.saleQty - data.MOQ, // Decrease saleQty by MOQ
      };

      // Create a new array with the updated item
      const updatedBarcodeData = [
        ...barcodeData.slice(0, itemIndex),
        updatedItem,
        ...barcodeData.slice(itemIndex + 1),
      ];

      // Update the state
      setBarcodeData(updatedBarcodeData);
    } else {
      message.warn("Minimum Qty Reached!");
    }
  };

  // Delete Qty
  const onDeleteQnty = (barcode) => {
    // Find the index of the item in barcodeData that matches the barcode
    const itemIndex = barcodeData.findIndex((item) => item.barcode === barcode);

    // If the item is not found, handle it accordingly
    if (itemIndex === -1) {
      message.error("Barcode not found");
      return;
    }

    // Create a new array without the item to be deleted
    const updatedBarcodeData = [
      ...barcodeData.slice(0, itemIndex),
      ...barcodeData.slice(itemIndex + 1),
    ];

    // Update the state
    setBarcodeData(updatedBarcodeData);
    message.warn("Barcode Removed!");
  };

  // On Restore Qty
  const onRestoreQnty = (barcode, data) => {
    setDeleteAvailable([...deletAvailable, data]);

    setBarcodeData((prev) => {
      // Check if the barcode already exists in the state
      const existingIndex = prev.findIndex((item) => item.barcode === barcode);
      if (existingIndex !== -1) {
        // If it exists, update the existing item
        const updatedData = {
          ...prev[existingIndex],
          saleQty: data.MOQ,
          recycleAvailable: false,
        };

        // Return a new array with the updated item
        return [
          ...prev.slice(0, existingIndex), // items before the found index
          updatedData, // updated item
          ...prev.slice(existingIndex + 1), // items after the found index
        ];
      } else {
        return [...prev];
      }
    });
  };

  /// Row Expandable Warnings  --- Start
  const expandedRowRender = (record) => {
    if (record.recycleAvailable) {
      return (
        <div style={{ color: "orange" }}>
          Recycle Available Kindly Restore This Product!
        </div>
      );
    }
    if (record.totalPurchaseValue <= 0) {
      return (
        <div style={{ color: "red" }}>Warning: Stock is Not Available!</div>
      );
    }
    if (record.saleQty > record.totalPurchaseValue) {
      return (
        <div style={{ color: "orange" }}>
          Warning: Only {record.totalPurchaseValue} Quantity Available
        </div>
      );
    }
  };

  const dataWithExpanded = barcodeData?.map((item) => ({
    ...item,
    expanded: true, // Set expanded to true for all rows
  }));
  /// Row Expandable Warnings  --- Close

  // Update Sale Final Function
  const updateBill = async () => {
    setLoading(true);
    // Check any out of stock product available or not
    const outOfQtyStock = barcodeData.some((obj) => {
      if (obj.recycleAvailable) return true;
      if (obj.totalPurchaseValue <= 0) return true;
      if (obj.saleQty > obj.totalPurchaseValue) return true;
      return false;
    });

    if (outOfQtyStock) {
      setLoading(false);
      message.warn("Please Check Unbill Products Quantity!");
      return;
    }

    const response = await dispatch(getSaleUsingInvoiceId(billData.invoiceId));
    let salebillData = [];
    if (response && response.status == 200) {
      salebillData = response.data || [];
    } else {
      setLoading(false);
      message.error("Something Went Wrong!");
      return;
    }

    let totalAmount = 0;

    const salesAll = salebillData?.map((obj) => {
      const barcodeMatch = barcodeData.find(
        (barcodeObj) => barcodeObj.barcode === obj.barcode
      );
      if (barcodeMatch) {
        totalAmount =
          totalAmount +
          Number(obj.details.wsp * (obj.quantity + barcodeMatch.saleQty));
        return {
          salesPersonName: obj.salesPersonId.name,
          salesPersonId: obj.salesPersonId._id,
          quantity: Number(obj.quantity + barcodeMatch.saleQty),
          barcode: obj.barcode,
          MOQ: obj.MOQ,
          _id: obj._id,
          // packagingType: obj?.packagingType || "",
          // packagingValue: obj?.packagingValue || "",
        };
      } else {
        totalAmount = totalAmount + Number(obj.details.wsp * obj.quantity);
        return {
          salesPersonName: obj.salesPersonId.name,
          salesPersonId: obj.salesPersonId._id,
          quantity: obj.quantity,
          barcode: obj.barcode,
          MOQ: obj.MOQ,
          _id: obj._id,
          // packagingType: obj?.packagingType || "",
          // packagingValue: obj?.packagingValue || "",
        };
      }
    });

    // Find barcodes in barcodeData that are not in salebillData
    const unmatchedBarcodes = barcodeData?.filter(
      (barcodeObj) =>
        !salebillData.some((saleObj) => saleObj.barcode === barcodeObj.barcode)
    );

    if (unmatchedBarcodes && unmatchedBarcodes.length > 0) {
      for (let index = 0; index < unmatchedBarcodes.length; index++) {
        totalAmount =
          totalAmount +
          Number(
            unmatchedBarcodes[index].wsp * unmatchedBarcodes[index].saleQty
          );
        salesAll.push({
          salesPersonName: salesAll[0].salesPersonName,
          salesPersonId: salesAll[0].salesPersonId,
          quantity: unmatchedBarcodes[index].saleQty,
          barcode: unmatchedBarcodes[index].barcode,
          MOQ: unmatchedBarcodes[index].MOQ,
          // packagingType: unmatchedBarcodes[index]?.packagingType || "",
          // packagingValue: unmatchedBarcodes[index]?.packagingValue || "",
        });
      }
    }

    let grAmount = billData.grAmountUsed;
    let tax = billData.tax;
    let discount = billData.discount;

    let mainAmount = Number(totalAmount - grAmount);
    const discountAmt = Number(mainAmount * (discount / 100));
    mainAmount = Number(mainAmount - discountAmt);
    const taxAmount = Number(mainAmount * (tax / 100));
    const netAmount = Number(mainAmount + taxAmount);

    const totalBoxes = salesAll.reduce((accumulator, current) => {
      return accumulator + current.quantity / current.MOQ;
    }, 0);

    const totalQty = salesAll.reduce((accumulator, current) => {
      return accumulator + current.quantity;
    }, 0);

    let saleObj = {
      salesOrder: billData.salesOrder,
      remark: billData.remark,
      pAndf: billData.pAndf,
      labour: billData.labour,
      tax: billData.tax,
      discount: billData.discount,
      userId: billData.userId._id,
      date: billData.date,
      deletedSales: [],
      isGrAmount: billData.grAmountUsed == 0 ? false : true,
      salesOrderChangeStatus: false,
      restoredSale: [],
      invoiceNumber: billData.invoiceId,

      totalQuantity: totalQty,
      netAmount: netAmount,
      totalAmount: totalAmount,
      totalBoxes: totalBoxes,
      sales: salesAll,
      noOfBoxes: 0,
      noOfPackets: 0,
    };

    // recheckVerified()
    // setLoading(false);

    await dispatch(updateSales(saleObj))
      .then((respo) => {
        if (respo.invoiceId) {
          setOpen(false);
          setLoading(false);
          setBarcodeData([]);
          setNoFoundBarcodes([]);
          setDeleteAvailable([]);
          recheckVerified();
        }
      })
      .catch((error) => console.log("catch", error))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row gutter={16} className="mt-4">
        <Col span={24} align="center">
          <Button type="primary" onClick={showDrawer}>
            <EditOutlined />
            Add In Bill
          </Button>
        </Col>
      </Row>
      <Drawer
        title="Add Unbill Products In Bill"
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        destroyOnClose={true}
        // size='large'
        style={{ minHeight: "450px !important" }}
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spin />
          </div>
        ) : (
          <>
            <Table
              dataSource={dataWithExpanded}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 500 }}
              expandable={{
                expandedRowRender: (record) => expandedRowRender(record),
                defaultExpandAllRows: true, // Allow all rows to be expandable
              }}
              //   rowClassName={(record, index) => {
              //     if (record.quantity != record.maxQuantity) {
              //       return "table-row-dark";
              //     }
              //   }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />

              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={100}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                // render={(value, item, index) =>
                //   `${item?.details?.designNumber}`
                // }
                width={115}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                // render={(value, item, index) => `${item?.details?.brand}`}
                width={120}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                // render={(value, item, index) => `${item?.details?.size}`}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={90}
                // render={(value, item, index) => `${item?.details?.color}`}
              />
              <Column
                title="Qnty"
                dataIndex="saleQty"
                key="saleQty"
                width={60}
              />
              <Column
                title="Recycle Available"
                dataIndex="recycleAvailable"
                key="recycleAvailable"
                width={120}
                render={(text, record, index) => {
                  if (text) {
                    return (
                      <Popconfirm
                        title="Are you sure to Restore this Product?"
                        onConfirm={() => onRestoreQnty(record.barcode, record)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button>Restore</Button>
                      </Popconfirm>
                    );
                  } else {
                    return "False";
                  }
                }}
              />
              <Column
                title="Action"
                key="action"
                width={70}
                align="center"
                render={(text, record, index) => (
                  <Row gutter={[8, 0]}>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Add Qnty">
                        <a
                          type="button"
                          onClick={() => onAddQnty(text.barcode, text)}
                        >
                          <PlusCircleOutlined />
                        </a>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Remove Qnty">
                        <a
                          type="button"
                          onClick={() => onRemoveQnty(text.barcode, text)}
                        >
                          <MinusCircleOutlined />
                        </a>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <a type="button">
                        <Popconfirm
                          title="Are you sure to delete this Product?"
                          onConfirm={() => onDeleteQnty(text.barcode, text)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete Product">
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      </a>
                    </Col>
                  </Row>
                )}
              />
              {/* <Column
                title="Add Packaging"
                key="action"
                width={150}
                align="center"
                render={(text, record, index) => (
                  <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {console.log("check mohity ds",text)}
                      {!text.packagingType || !text.packagingValue ? (
                        <Tooltip title="Add Qnty">
                        <a
                          type="button"
                          onClick={() => onAddQnty(text.barcode, text)}
                        >
                          <PlusCircleOutlined />
                        </a>
                      </Tooltip>
                      ) : (
                        <Tag color="blue">Available</Tag>
                      )}
                    </Col>
                  </Row>
                )}
              /> */}
            </Table>
            <Row gutter={16} className="mt-4">
              <Col span={24} align="center">
                <Button
                  type="primary"
                  disabled={barcodeData.length > 0 ? false : true}
                  onClick={() => updateBill()}
                >
                  <EditOutlined />
                  Update Bill
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Drawer>
    </>
  );
};
export default UnbillProductBillCreate;
