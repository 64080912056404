import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  message,
  Upload,
  Spin,
  Radio,
  Checkbox,
} from "antd";
import {
  CloudUploadOutlined,
  UploadOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  addSingleGlobalEcomImage,
  saveEcomBanner,
  saveCheckOutCartImages,
  getLatestBannerList,
  getAllCheckoutCartImages,
} from "../../../redux/actions/purchaseAction";

const UploadBannerModal = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalloading, setFinalLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [bannerImage, setBannerImage] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [bannerType, setBannerType] = useState("home");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const dispatch = useDispatch();

  let idealWidth = isMobile ? 1080 : 1440;
  let idealHeight = isMobile ? 1080 : 430;

  let idealWidthCheckOut = isMobile ? 390 : 1200;
  let idealHeightCheckOut = isMobile ? 250 : 306;

  const showModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setLoading(false);
    setImageUrl("");
    setBannerImage([]);
    setErrorMsg(null);
  };

  const handleChange = async ({ fileList }) => {
    const file = fileList[0].originFileObj;
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
      const image = new Image();
      image.src = imageDataUrl;
      image.onload = async () => {
        if (bannerType === 'home') {

          if (image.width !== idealWidth || image.height !== idealHeight) {
            message.error(
              `Please upload a banner with dimensions ${idealWidth}x${idealHeight}`
            );
            setErrorMsg(
              `Please upload a banner with dimensions ${idealWidth}x${idealHeight}`
            );
            return;
          }

        } else if (
          image.width !== idealWidthCheckOut ||
          image.height !== idealHeightCheckOut
        ) {
          message.error(
            `Please upload a banner with dimensions ${idealWidthCheckOut}x${idealHeightCheckOut}`
          );
          setErrorMsg(
            `Please upload a banner with dimensions ${idealWidthCheckOut}x${idealHeightCheckOut}`
          );
          return;
        }
        setBannerImage(fileList);
        setLoading(true);
        let response = await dispatch(addSingleGlobalEcomImage(file));
        if (response?.status === 200) {
          setImageUrl(response.data.filePath);
        }
        setLoading(false);
      };
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    if (!imageUrl || bannerImage.length === 0) {
      message.warn("Please upload an image before submitting!");
      return;
    }
    setLoading(true);
    // const saveAction = bannerType === "home" ? saveEcomBanner : saveCheckOutCartImages;
    // await dispatch(saveAction({ bannerImages: imageUrl, isMobile }));
    if (bannerType === "home") {
      dispatch(saveEcomBanner({ bannerImages: imageUrl, isMobile })).then(
        () => {
          dispatch(getLatestBannerList());
          dispatch(getAllCheckoutCartImages());
        }
      );
    } else {
      dispatch(saveCheckOutCartImages({ image: imageUrl, isMobile })).then(
        () => {
          dispatch(getLatestBannerList());
          dispatch(getAllCheckoutCartImages());
        }
      );
    }

    setLoading(false);
    closeModal();
  };

  const handleDelete = () => {
    setImageUrl(""); // Clear the uploaded image URL
    setBannerImage([]); // Clear the banner image state
  };

  const handlePreview = () => {
    setPreviewImage(imageUrl); // Set the preview image
    setPreviewOpen(true); // Open the preview modal
  };

  return (
    <>
      <Button
        className="ok-modal"
        style={{ color: "#ffff" }}
        onClick={showModal}
      >
        <CloudUploadOutlined /> Upload
      </Button>
      <Modal
        visible={visible}
        onCancel={closeModal}
        title="Upload Banner"
        footer={null}
        destroyOnClose
      >
        <Radio.Group
          onChange={(e) => setBannerType(e.target.value)}
          value={bannerType}
        >
          <Radio value="home">Home Page Banner</Radio>
          <Radio value="checkout">Checkout Banner</Radio>
        </Radio.Group>
        <Checkbox
          className="mt-5"
          onChange={(e) => setIsMobile(e.target.checked)}
        >
          Upload For Mobile
        </Checkbox>
        <p style={{ color: "red" }}>{errorMsg}</p>

        <Upload
          name="file"
          listType="picture-card"
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleChange}
        >
          {bannerImage.length === 0 && !loading ? (
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          ) : null}
        </Upload>

        {imageUrl && !loading && (
          <div className="image-preview">
            <img
              src={imageUrl}
              alt="Uploaded Banner"
              style={{
                width: "100%",
                maxHeight: "300px",
                objectFit: "contain",
              }}
            />
            <div
              className="imagePreviewUpload text-center"
              style={{ marginTop: "10px" }}
            >
              <button
                className="border-0 bg-transparent"
                danger
                onClick={handleDelete}
                style={{ marginLeft: "10px" }}
              >
                <DeleteOutlined />
              </button>
            </div>
          </div>
        )}

        {
          bannerType === 'home'
            ?
            <p>Note: Please upload a banner with dimensions {idealWidth}x{idealHeight}.</p>
            :
            <p>Note: Please upload a banner with dimensions {idealWidthCheckOut}x{idealHeightCheckOut}.</p>
        }
        <Row className="mt-2" justify="center">
          <Col>
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                loading={finalloading}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Modal>

      {/* Preview Modal */}
      <Modal
        visible={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={800}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadBannerModal;
