import React, { Component } from "react";
import {
  Table,
  Tooltip,
  Button,
  Space,
  Input,
  Modal,
  Row,
  Col,
  Popconfirm,
} from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { getPurchaseReturnBillsDetailsById } from "../../../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
// import AddImages from "./AddImages";
import UpdatePurchase from "../UpdatePurchase";
import "../../../../../assets/css/barcode.css";
import { CSVLink } from "react-csv";
import gifLoader from "../../../../../assets/images/giphy.gif";
import { CaretRightOutlined } from "@ant-design/icons";

var Barcode = require("react-barcode");

const { Column } = Table;

class ShowBillReturnPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      visible: false,
      visibleB: false,
      barcodes: [],
    };
  }

  showModal = () => {
    this.props.getPurchaseReturnBillsDetailsById(this.props.purchaseId, 1);
    this.setState({
      visible: true,
    });
  };

  // showModalBarcode = () => {
  //   this.setState({
  //     visibleB: true,
  //     barcodes: this.props.purchases,
  //   });
  // };
  handleModalCancel = () => {
    this.setState({ visible: false });
  };
  handleModalCancelBarcode = () => {
    this.setState({ visibleB: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  //  New Fun To Calculatre  - Net Amount
  calculateFinalData = () => {
    let totalAmt = this.props?.purchases?.billsData?.totalPrice;
    let tax = this.props?.purchases?.data?.[0]?.tax;
    let discount = this.props?.purchases?.data?.[0]?.discount;

    const calCulateNetAmt = (amount, tax, discount) => {
      let discountedAmt = (amount * discount) / 100;
      let netAmt = amount - discountedAmt;
      let taxedAmt = (netAmt * tax) / 100;
      netAmt = netAmt + taxedAmt;
      return netAmt ;
    };

    let totalNetAmount = calCulateNetAmt(totalAmt, tax, discount);

    return {
      totalNetAmount,
    };
  };

  render() {
    let calculateData = this.calculateFinalData();
    const { purchases } = this.props;
    const { visible, visibleB } = this.state;
    let count = 0;
    // let d = new Date();
    // const num = d.getFullYear();
    // const last2Str = String(num).slice(-2);
    // let month = "" + (d.getMonth() + 1);
    // if (month.length < 2) month = "0" + month;
    // let out = month + last2Str;

    const createdAt =  purchases && purchases.length > 0 && purchases?.[0]?.created_at;
    const date = new Date(createdAt);
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (month < 10) month = "0" + month;
    const last2Str = String(year).slice(-2);
    const out = month + last2Str;
    
    let barcodes = this.state.barcodes.map((barcode) => {
      let barcodeData = [];

      let data = (
        <Col sm={24} md={12} lg={12} xl={12} className="">
          <div
            className="box-barcode"
            style={{
              // width: "6cm",
              marginRight: "70px",
              marginLeft: "15px",
              marginTop: "-15px",
              flexGrow: 3,
            }}
          >
            <h3 style={{ marginBottom: "0px" }}> {barcode.brand}</h3>
            <Row className="code-row-one">
              <Col sm={12} md={12} lg={12} xl={12}>
                <p style={{ color: "black" }}>D.No. -{barcode.designNumber}</p>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12} className="code-col">
                <p style={{ marginLeft: "5px", color: "black" }}>
                  {" "}
                  {barcode.MOQ}
                </p>
              </Col>
            </Row>
            <Row gutter={[8, 0]} className="code-row">
              <Col sm={12} md={12} lg={12} xl={12}>
                <p style={{ color: "black" }}>Size. - {barcode.size}</p>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12} className="code-col">
                <p style={{ color: "black" }}>{barcode.color}</p>
              </Col>
            </Row>
            <h4 className="code" style={{ marginLeft: "30%" }}>
              91{barcode.wsp}
            </h4>
            <Barcode
              height={30}
              width={1.5}
              marginTop={-4}
              marginBottom={-4}
              displayValue={false}
              value={barcode.barcode}
              className="barcode-strip"
            />
            <h5 className="barcode-number mb-2" style={{ marginLeft: "3.5%" }}>
              {Math.floor(1000 + Math.random() * 9000)}
              {barcode.barcode}
              {out}
            </h5>
          </div>
        </Col>
      );
      let totalBoxesTimes = Math.ceil(Number(barcode.totalBox));
      barcodeData = Array(totalBoxesTimes).fill(data);
      count = count + totalBoxesTimes;
      return barcodeData;
    });

    const headers = [
      { label: "Barcode", key: "barcode" },
      { label: "Purchase Id", key: "purchaseId" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Brand", key: "brand" },
      { label: "Design Number", key: "designNumber" },
      { label: "C_Date", key: "created_at" },
      { label: "Qnty", key: "quantity" },
      { label: "Purchase Price", key: "purchasePrice" },
      { label: "Total Amount", key: "price" },
    ];
    return (
      <div>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="View Purchases">
            <EyeOutlined />
          </Tooltip>
        </button>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="View Purchases"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          {purchases && purchases.data && purchases.data.length > 0 ? (
            <>
              {/* <Row gutter={24} className="mb-4 grand-totall billHeadingContainer" >
                <Col sm={24} md={12} lg={6}>
                  <span className=" billsTopHeading">C_Date:</span>
                  <span className="billsSecHeading">{`${moment(this.props.data.created_at).format("YYYY-MM-DD")} (${moment(this.props.data.created_at).format("HH:mm:ss")} )`}</span>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <span className=" billsTopHeading">Purchase Return ID:</span>
                  <span className="billsSecHeading">{this.props.data.purchaseReturnBillId}</span>
                </Col>
                <Col sm={24} md={24} lg={10}>
                  <span className=" billsTopHeading">Dealer Name:</span>
                  <span className="billsSecHeading">{this.props.data.dealerName}</span>
                </Col>
              </Row> */}

              <Row
                gutter={24}
                className="mb-4 grand-totall billHeadingContainer"
              >
                <Col sm={24} md={24} lg={24} className='breadcrumbHeadingView'>
                  <div className=" billsTopHeading">
                    C_Date
                    <CaretRightOutlined className="mr-2" />
                    {`${moment(this.props.data.created_at).format(
                      "YYYY-MM-DD"
                    )} (${moment(this.props.data.created_at).format(
                      "HH:mm:ss"
                    )} )`}
                  </div>

                  <div className=" billsTopHeading breadcrumbSubHeadings">
                    Purchase Return ID
                    <CaretRightOutlined className=" mr-2" />
                    {this.props.data.purchaseReturnBillId}
                  </div>

                  <div className=" billsTopHeading breadcrumbSubHeadings">
                    Dealer Name
                    <CaretRightOutlined className=" mr-2" />{" "}
                    {this.props.data.dealerName}
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={
                  this.props.purchases && this.props.purchases.data
                    ? this.props.purchases.data
                    : []
                }
                bordered="true"
                size="middle"
                // pagination={{
                //   pageSize: 10,
                //   total:
                //     this.props.purchases && this.props.purchases.count
                //       ? this.props.purchases.count
                //       : 0,
                //   onChange: (page) => {
                //     this.props.getPurchaseReturnBillsDetailsById(
                //       this.props.purchaseId,
                //       page
                //     );
                //   },
                // }}
                pagination={false}
                scroll={{ y: 240 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Purchase Id"
                  dataIndex="purchaseId"
                  key="purchaseId"
                  width={150}
                  {...this.getColumnSearchProps("purchaseId")}
                />
                <Column
                  title="Design No"
                  dataIndex="designNumber"
                  key="designNumber"
                  width={150}
                  {...this.getColumnSearchProps("designNumber")}
                />
                <Column
                  title="Brand"
                  dataIndex="brand"
                  key="brand"
                  width={100}
                  {...this.getColumnSearchProps("brand")}
                />
                <Column
                  title="Size"
                  dataIndex="size"
                  key="size"
                  width={150}
                  {...this.getColumnSearchProps("size")}
                />
                <Column
                  title="Color"
                  dataIndex="color"
                  key="color"
                  width={150}
                  {...this.getColumnSearchProps("color")}
                />
                <Column
                  title="MOQ"
                  dataIndex="MOQ"
                  key="MOQ"
                  width={150}
                  {...this.getColumnSearchProps("MOQ")}
                />
                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={60}
                />
                <Column
                  title="Purchase Price"
                  dataIndex="purchasePrice"
                  key="purchasePrice"
                  width={140}
                />
                <Column
                  title="Sale Price"
                  dataIndex="wsp"
                  key="wsp"
                  width={140}
                />
                <Column
                  title="Amount"
                  dataIndex="purchasePrice"
                  key="purchasePrice"
                  width={140}
                  render={(value, item, index) =>
                    Number(item.quantity * item.purchasePrice)
                  }
                />
              </Table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
                className="mt-4"
              >
                {(() => {
                  if (
                    this.props &&
                    this.props.purchases &&
                    this.props.purchases.data
                  ) {
                    return (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="save-modal "
                        block
                        style={{ width: "10%" }}
                      >
                        <CSVLink
                          filename={`${"Purchase"}-${
                            this.props.purchases.data[0].purchaseId
                          }.csv`}
                          data={this.props.purchases.data}
                          headers={headers}
                        >
                          Download CSV
                        </CSVLink>
                      </Button>
                    );
                  }
                })()}
              </div>
              <div className="mt-4">
                <div className="col-md-12 ml-auto">
                  <div
                    className="grand-totall"
                    style={{ padding: "18px 17px 4px" }}
                  >
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Purchase Return Total
                      </h4>
                    </div>
                    <h5>
                      Total Quantity{" "}
                      <span>{this.props.data.totalQuantity}</span>
                    </h5>
                    <h5>
                      Total Boxes <span>{this.props.data.totalBoxes}</span>
                    </h5>
                    {/* <h5>
                      Total Barcodes{" "}
                      <span>
                        {this.props && this.props.purchases && this.props.purchases.data ? this.props.purchases.data.length : 0}
                      </span>
                    </h5> */}

                    {/* <h4 className="grand-totall-title">
                      Net Amount <span>{calculateData.totalNetAmount}</span>
                    </h4> */}
                    <h5 className="grand-totall-title">
                      Net Amount{" "}
                      <span>{calculateData.totalNetAmount}</span>
                    </h5>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <img src={gifLoader} width={"300px"} height={"300px"} />
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

ShowBillReturnPurchase.propTypes = {
  getPurchaseReturnBillsDetailsById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  purchases: state.purchaseData.purchaseBillListById,
});

export default connect(mapStateToProps, { getPurchaseReturnBillsDetailsById })(
  ShowBillReturnPurchase
);
