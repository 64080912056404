import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Popconfirm, Table, Checkbox, message, Button, Radio, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getBannerList, deleteEcomBanner, sendBannerImages, getLatestBannerList, getAllCheckoutCartImages, deleteCheckoutCartImage, setCheckoutCartsImage } from "../../../redux/actions/purchaseAction";
import { DeleteOutlined } from "@ant-design/icons";
import UploadBannerModal from "./UploadBannerModal";
import PreviewBanner from "./PreviewBanner";

const deviceOptions = [
  { label: "Web", value: false },
  { label: "Mobile", value: true },
];

const bannerOptions = [
  { label: "Home", value: "home" },
  { label: "Checkout", value: "checkout" },
];

const { Column } = Table;

const UploadBanner = () => {
  const [imageData, setImageData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [bannerType, setBannerType] = useState("home");
  const [selectedImage, setSelectedImage] = useState(null);
  const [disable, setDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);

  const dispatch = useDispatch();
  const bannersList = useSelector((state) => state.purchaseData.bannerList);
  const latestBannerList = useSelector((state) => state.purchaseData.latestBannerList);
  const checkoutBannerList = useSelector((state) => state.purchaseData.checkoutBannerList?.data);

  const fetchBannerData = useCallback(async () => {
    await dispatch(getBannerList());
  }, [dispatch]);

  const fetchLatestBannerData = useCallback(async () => {
    await dispatch(getLatestBannerList());
    await dispatch(getAllCheckoutCartImages());
  }, [dispatch]);

  useEffect(() => {
    fetchBannerData();
    fetchLatestBannerData();
  }, [fetchBannerData, fetchLatestBannerData]);

  useEffect(() => {
    if (bannerType === "home" && bannersList?.data) {
      const { oldImages, mobileBannerImages } = bannersList.data;
      const images = isMobile ? mobileBannerImages : oldImages;
      setImageData(images?.map((img) => ({ imageUrl: img, status: false })) || []);
      setSelectedImage(null);
    } else if (
      bannerType === "checkout" &&
      checkoutBannerList &&
      (checkoutBannerList.mobileImages?.length || checkoutBannerList.oldImages?.length)
    ) {
      const images = isMobile ? checkoutBannerList.mobileImages : checkoutBannerList.oldImages;
      setImageData(images?.map((img) => ({ imageUrl: img, status: false })) || []);
      setSelectedImage(null);
    } else {
      setImageData([]);
      setSelectedImage(null);
    }
    setDisable(true);
  }, [bannersList, checkoutBannerList, isMobile, bannerType]);

  const handleCheckboxChange = (e, value) => {
    if (bannerType === "home") {
      const newData = imageData.map((item) => {
        if (item.imageUrl === value.imageUrl) {
          const selectedCount = imageData.filter((img) => img.status).length;

          if (selectedCount === 4 && !item.status) {
            message.warn("You can select only 4 images");
            return item;
          }

          const newStatus = !item.status;
          const updatedSelectedCount = newStatus ? selectedCount + 1 : selectedCount - 1;

          setDisable(updatedSelectedCount !== 4);
          return { ...item, status: newStatus };
        }
        return item;
      });

      setImageData(newData);
    } else if (bannerType === "checkout") {
      setSelectedImage(value.imageUrl);
      setDisable(false);
    }
  };

  const handleSave = async () => {
    setLoader(true);
    const selectedImages =
      bannerType === "home"
        ? imageData.filter((img) => img.status).map((img) => img.imageUrl)
        : selectedImage
          ? [selectedImage]
          : [];

    try {
      let res;
      if (bannerType === "home") {
        res = await dispatch(sendBannerImages({ bannerImages: selectedImages, isMobile }));
      } else if (bannerType === "checkout" && checkoutBannerList?._id) {
        res = await dispatch(setCheckoutCartsImage({
          _id: checkoutBannerList._id,
          image: selectedImages,
          isMobile
        }));
      }

      if (res?.status === 200) {
        message.success(res?.data?.message || "Banners updated successfully!");
        setTimeout(() => window.location.reload(), 1000);
      } else {
        message.error(res?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      message.error("Failed to update banners.");
    } finally {
      setLoader(false);
    }
  };

  const handleDeviceChange = (e) => {
    setViewLoading(true);
    setIsMobile(e.target.value);
    setDisable(true);
    setTimeout(() => setViewLoading(false), 500);
  };

  const handleBannerTypeChange = (e) => {
    setViewLoading(true);
    setBannerType(e.target.value);
    setDisable(true);
    setTimeout(() => setViewLoading(false), 500);
  };

  const deleteImage = async (record) => {
    if (bannerType === "home" && bannersList?.data?._id) {
      await dispatch(deleteEcomBanner({
        bannerImages: record.imageUrl,
        _id: bannersList.data._id,
        isMobile
      }));
    } else if (bannerType === "checkout" && checkoutBannerList?._id) {
      await dispatch(deleteCheckoutCartImage({
        _id: checkoutBannerList._id,
        image: record.imageUrl,
        isMobile
      }));
    }
    dispatch(getLatestBannerList());
    dispatch(getAllCheckoutCartImages());
  };

  return (
    <div className="subCategory-dashboard">
      <div className="container-fluid category-list">
        <Row className="title-row">
          <Col xs={10}>
            <div className="page-header">Upload Banner</div>
          </Col>
          <Col xs={7} align="center">
            <Radio.Group options={bannerOptions} value={bannerType} optionType="button" buttonStyle="solid" onChange={handleBannerTypeChange} className="mx-4" />
            <Radio.Group options={deviceOptions} value={isMobile} optionType="button" buttonStyle="solid" onChange={handleDeviceChange} />
          </Col>
          <Col xs={3} align="end">
            <PreviewBanner bannerType={bannerType} images={bannerType === "home" ? latestBannerList : checkoutBannerList} />
          </Col>
          <Col xs={3} align="end">
            <UploadBannerModal />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={24}>
            <Spin spinning={viewLoading} size="large">
              <Table className="cartTable" dataSource={imageData} pagination={false} rowKey="imageUrl">
                <Column title="SRN" render={(value, item, index) => index + 1} width={60} />
                <Column title="Image" dataIndex="imageUrl" width={200} render={(image) => <img className="img-fluid cartProductImage" src={image} alt="Banner" />} />
                <Column
                  title="Select"
                  render={(value) =>
                    bannerType === "checkout" ? (
                      <Radio checked={selectedImage === value.imageUrl} onChange={(e) => handleCheckboxChange(e, value)} />
                    ) : (
                      <Checkbox checked={value.status} onChange={(e) => handleCheckboxChange(e, value)} />
                    )
                  }
                  width={60}
                />
                <Column
                  title="Delete"
                  render={(value, record) => (
                    <Popconfirm title="Are you sure to Delete This Image?" onConfirm={() => deleteImage(record)} okText="Yes" cancelText="No">
                      <DeleteOutlined />
                    </Popconfirm>
                  )}
                  width={60}
                />
              </Table>
            </Spin>
          </Col>
        </Row>

        {!disable && (
          <Row className="mt-4">
            <Col xs={24}>
              <Button loading={loader} disabled={disable} type="primary" onClick={handleSave} block>
                Save
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default UploadBanner;
