import React, { Component } from "react";
import {
  Table,
  Tooltip,
  Button,
  Space,
  Input,
  Modal,
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  message,
} from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSaleProductByInvoiceId,
  getDetailSearch,
} from "../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import gifLoader from "../../../assets/images/giphy.gif";
import { getCustomers } from "../../../redux/actions/customerAction";
import moment from "moment";
import ShowBillProducts from "./ShowBillProducts";
import ViewGenerateBill from "./ViewGenerateBill";
import VerifyBillProduct from "./VerifyBillProduct";
const { Column } = Table;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
class DetailsSearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: null,
      page: "",
      current: 1,
      search: "",
      visible: false,
      customerId: "",
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
  }

  showModal = () => {
    // this.props.getSaleProductByInvoiceId(this.props.invoiceId);
    this.setState({
      visible: true,
    });
  };
  componentDidMount = () => {
    window.addEventListener("keydown", this.onKeyDown);
  };
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }
  onKeyDown = (event) => {
    const { selectedRowId } = this.state;
    const currentIndex = this.props.saleProducts?.findIndex(
      (item) => item?._id === selectedRowId
    );
    let nextIndex;
    if (event.key === "ArrowDown") {
      // Move to the next row
      nextIndex = (currentIndex + 1) % this.props.saleProducts.length;
    } else if (event.key === "ArrowUp") {
      // Move to the previous row
      nextIndex =
        (currentIndex - 1 + this.props.saleProducts.length) %
        this.props.saleProducts.length;
    } else {
      return; // Do nothing if a different key is pressed
    }

    this.setState({
      selectedRowId: this.props.saleProducts[nextIndex]._id,
    });
  };
  onRowClick = (record) => {
    this.setState({
      selectedRowId: record._id,
    });
  };

  handleModalCancel = () => {
    this.props.getDetailSearch("reset");
    this.setState({
      visible: false,
      customerId: "",
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  getData = (name) => {
    if (name == "customer") this.props.getCustomers("STORE_USER");
  };

  handleChangeCustomer = (key, value) => {
    console.log("samay value", value);
    this.setState({
      customerId: value.value,
    });
  };

  onStartDateChange = (date, dateString) => {
    const startDate = new Date(dateString);
    const endDate = new Date(this.state.dateString);
    this.setState({ startDate: dateString });
  };

  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  showDataOnList = () => {
    const { startDate, endDate } = this.state;
    const { salesData } = this.props;
    let today = moment(new Date()).format("YYYY-MM-DD");
    let reportObj;
    if (today == endDate) {
      reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
        customerId: this.state.customerId,
      };
    } else {
      const dateMoment = moment(endDate, "YYYY-MM-DD");
      const endTime = dateMoment.endOf("day").format("HH:mm:ss");
      const endDateWithEndTime = `${endDate} ${endTime}`;
      reportObj = {
        startDate: startDate,
        endDate: endDateWithEndTime,
        customerId: this.state.customerId,
      };
    }
    if (reportObj.customerId) {
      console.log("hello mohit test", reportObj);
      this.props.getDetailSearch(reportObj);
    } else {
      message.warn("Please Select Customer Name");
    }
  };

  render() {
    const { visible } = this.state;
    const { salesData } = this.props;
    console.log("#this.props.saleProducts", this.props.saleProducts);
    let customerOptions = this.props.customers?.map((customer) => {
      return (
        <Option
          name={[
            customer.transport,
            customer.agentId,
            customer.grAmount,
            customer,
          ]}
          value={customer?._id}
        >
          {customer?.name}
        </Option>
      );
    });
    return (
      <div>
        <Button
          className="close-modal-details"
          type="button"
          onClick={this.showModal}
        >
          Detail Search
        </Button>
        <Modal
          open={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Detail Search"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Tooltip title="Select Customer">
                <Form.Item
                  name="customerName"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Customer Name !",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Customer"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={this.handleChangeCustomer}
                    onClick={() => this.getData("customer")}
                    onInputKeyDown={() => this.getData("customer")}
                  >
                    {customerOptions}
                  </Select>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Form
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                // initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]}>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please Select Start Date !",
                        //   },
                        // ]}
                      >
                        {/* <DatePicker
                  style={{ width: "100%" }}
                  defaultValue={moment()}
                  disabledDate={(current) => {
                    return current && current > Date.now();
                  }}
                  onChange={this.onStartDateChange}
                  placeholder="Select Start Date"
                  format={dateFormat}
                /> */}
                        {console.log("")}
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          value={moment(this.state.startDate, dateFormat)}
                          format={dateFormat}
                          showClose={false}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Tooltip title="Select End Date">
                      <Form.Item name="endDate">
                        {/* <DatePicker
                  defaultValue={moment()}
                  disabledDate={(current) => {
                    return current && current > Date.now();
                  }}
                  style={{ width: "100%" }}
                  onChange={this.onEndDateChange}
                  placeholder="Select End Date"
                  format={dateFormat}
                /> */}
                        {console.log("")}
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          value={moment(this.state.endDate, dateFormat)}
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>

                  {/* <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                        <Form.Item>
                                            <Button type="primary" block >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col> */}
                </Row>
              </Form>
            </Col>
          </Row>
          <Table
            dataSource={salesData?.data}
            onRow={(record) => ({
              onClick: () => this.onRowClick(record),
            })}
            // rowClassName={(record) => (record._id === this.state.selectedRowId ? 'selected-row' : '')}
            bordered="true"
            size="middle"
            pagination={false}
            scroll={{ y: 500 }}
            summary={() => (
              <Table.Summary fixed={"bottom"}>
                {console.log("Line No: 399", salesData)}
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    className="grand-Total-summary"
                    index={1}
                    colSpan={2}
                  >
                    Grand Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {salesData.count ? salesData.count : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={2}>
                    {}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {" "}
                    {salesData.allBoxes ? salesData.allBoxes : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {" "}
                    {salesData.allQuantity ? salesData.allQuantity : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {salesData?.allTotalAmount ? salesData.allTotalAmount : ""}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={1}>
                    {salesData.allNetAmount
                      ? parseInt(salesData.allNetAmount).toFixed()
                      : 0}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />
            <Column
              title="Bill Date"
              dataIndex="date"
              key="date"
              width={210}
              render={(value, item, index) =>
                `${moment(item.date).format("YYYY-MM-DD")} (${moment(
                  item.date
                ).format("HH:mm:ss")} )`
              }
            />

            <Column
              title="Invoice No."
              dataIndex="invoiceId"
              key="invoiceId"
              width={150}
              {...this.getColumnSearchProps("invoiceId")}
              render={(value, item, index) => {
                if (item && item.invoiceId) {
                  return `S-${item.invoiceId}`;
                }
              }}
            />
            <Column
              title="Customer Name"
              dataIndex="c_name"
              key="c_name"
              {...this.getColumnSearchProps("c_name")}
              render={(value, item, index) =>
                `${
                  item && item.userId && item.userId.name
                    ? item.userId.name
                    : ""
                }`
              }
              width={190}
            />
            <Column
              title="Agent Name"
              dataIndex="userId"
              key="userId"
              render={(value, item, index) =>
                `${item?.userId?.agentId ? item?.userId?.agentId?.name : ""}`
              }
              width={170}
            />
             <Column
              title="Discount"
              dataIndex="discount"
              key="discount"
              width={120}
            />
            <Column
              title="Total Boxes"
              dataIndex="totalBoxes"
              key="totalBoxes"
              width={120}
            />
            <Column
              title="Total Qnty"
              dataIndex="totalQuantity"
              key="totalQuantity"
              width={120}
            />
            <Column
              title="Total Amt"
              dataIndex="totalAmount"
              key="totalAmount"
              render={(value, item, index) =>
                parseInt(item.totalAmount).toFixed()
              }
              width={100}
            />
            <Column
              title="Gr Amt"
              dataIndex="grAmountUsed"
              key="grAmountUsed"
              render={(value, item, index) =>
                Number(item.grAmountUsed).toFixed()
              }
              // render={(value, item, index) => `${item.userId.grAmount.toFixed()}`}
              width={100}
            />
            <Column
              title="Net Amt"
              dataIndex="netAmount"
              key="netAmount"
              width={120}
              render={(value, item, index) =>
                parseInt(item.netAmount).toFixed()
              }
            />
            <Column
              title="Remark"
              dataIndex="remark"
              key="remark"
              width={120}
            />
            <Column
              title="Verified"
              dataIndex="isVerified"
              key="isVerified"
              width={120}
              render={(value, item, index) => {
                return `${item.isVerified}`;
              }}
            />
            <Column
              title="Action"
              key="action"
              width={180}
              render={(text, record) => (
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <a>
                      <ShowBillProducts
                        invoiceId={record.invoiceId}
                        data={record}
                      />
                    </a>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <div className="btn">
                      <ViewGenerateBill
                        text={text}
                        record={record}
                        saleObj={this.props.salesOrders}
                      />
                    </div>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <a>
                      <VerifyBillProduct invoiceId={record.invoiceId} />
                    </a>
                  </Col>
                </Row>
              )}
            />
            <Column
              title="Updated Date"
              dataIndex="updatedAt"
              key="updatedAt"
              width={210}
              render={(value, item, index) => {
                if (item && item.updatedAt) {
                  return `${moment(item.updatedAt).format(
                    "YYYY-MM-DD"
                  )} (${moment(item.updatedAt).format("HH:mm:ss")} )`;
                }
              }}
            />
          </Table>
        </Modal>
      </div>
    );
  }
}

DetailsSearchList.propTypes = {
  getSaleProductByInvoiceId: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getDetailSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saleProducts: state.saleData.saleProducts,
  customers: state.customerData.customers,
  salesData: state.saleData.saleswithcustomer,
});

export default connect(mapStateToProps, {
  getSaleProductByInvoiceId,
  getCustomers,
  getDetailSearch,
})(DetailsSearchList);
