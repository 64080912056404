import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPurchaseByPo, getPurchaseOrderByID } from "../../../../../redux/actions/purchaseOrderAction";
import { Table, Button, Card, Row, Col, message } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PurchaseOrderGenerateBillUpdate from "./PurchaseOrderGenerateBillUpdate";
import moment from "moment";
import PurchaseOrderGenerateBillUpdate2 from "./PurchaseOrderGenerateBillUpdate2";

const PurchaseOrderGenerateBillList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = location.state || {};
    const [saveClicked, setSaveClicked] = useState(false)
    const [purchaseId, setPurchaseId] = useState([])
    const purchaseOrderData = useSelector((state) => state.purchaseOrderData.purchaseOrdersByID);
    // billDetails
    const [updatedPurchaseOrderData, setUpdatedPurchaseOrderData] = useState([]);

    const columns = [
        {
            title: "SRN",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Dealer Name',
            dataIndex: 'dealerName',
            key: 'dealerName',
        },
        {
            title: 'Design No.',
            dataIndex: 'designNumber',
            key: 'designNumber',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: 'MOQ',
            dataIndex: 'MOQ',
            key: 'MOQ',
        },
        {
            title: 'Qnty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'purchasePrice',
            key: 'purchasePrice',
        },
        {
            title: 'WSP',
            dataIndex: 'wsp',
            key: 'wsp',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Row gutter={[8, 0]}>
                        <Col sm={24} md={24} lg={24}>
                            <Button className="" style={{ color: 'black' }} type="link" >
                                {
                                    purchaseId.includes(record._id)
                                        ? (
                                            <PurchaseOrderGenerateBillUpdate
                                                saveClicked={true}
                                                clickOnSave={clickOnSave}
                                                data={record}
                                                updatedData={updatedData}
                                            />
                                        )
                                        : (
                                            <PurchaseOrderGenerateBillUpdate
                                                clickOnSave={clickOnSave}
                                                data={record}
                                                updatedData={updatedData}
                                            />
                                        )
                                }
                            </Button>
                        </Col>
                    </Row>
                </>
            ),
        },
    ];

    const columns2 = [
        {
            title: "SRN",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Dealer Name',
            dataIndex: 'dealerName',
            key: 'dealerName',
        },
        {
            title: 'Design No.',
            dataIndex: 'designNumber',
            key: 'designNumber',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: 'MOQ',
            dataIndex: 'MOQ',
            key: 'MOQ',
        },
        {
            title: 'Qnty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'purchasePrice',
            key: 'purchasePrice',
        },
        {
            title: 'WSP',
            dataIndex: 'wsp',
            key: 'wsp',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Row gutter={[8, 0]}>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="" style={{ color: 'black' }} type="link">
                                {
                                    (() => {
                                        const foundItem = purchaseOrderData?.purchaseOrder.find((item) => item._id === record._id);
                                        const quantity = foundItem ? foundItem.quantity : null;
                                        return (
                                            <PurchaseOrderGenerateBillUpdate2
                                                Quantity={quantity}
                                                clickOnSave={clickOnSave}
                                                data={record}
                                                updatedData={updatedData}
                                            />
                                        );
                                    })()
                                }
                            </Button>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="border-0" onClick={() => handleDelete(record._id)}>
                                <DeleteOutlined />
                            </Button>
                        </Col>
                    </Row>

                </>
            ),
        },
    ];

    useEffect(() => {
        const obj = {
            poId: id
        };
        dispatch(getPurchaseOrderByID(obj));
    }, [id]);


    const updatedData = (updatedItem) => {
        setUpdatedPurchaseOrderData((prevData) => {
            const existingIndex = prevData.findIndex(item => item._id === updatedItem._id);

            if (existingIndex !== -1) {
                const updatedArray = [...prevData];
                updatedArray[existingIndex] = updatedItem;
                return updatedArray;
            } else {
                return [...prevData, updatedItem];
            }
        });
    };

    const clickOnSave = (id) => {
        setPurchaseId(prevIds => [...prevIds, id]);
        setSaveClicked(true)
    }

    const handleGenerateBill = () => {
        const location = purchaseOrderData?.purchaseOrder?.[0]?.location
        const total = updatedPurchaseOrderData?.reduce((sum, item) => sum + (Number(item.wsp || item.purchasePrice) * Number(item.quantity)), 0)
        const discountedAmount = purchaseOrderData?.billDetails?.discount > 0
            ?
            (total / purchaseOrderData?.billDetails?.discount) / 100
            : 0
        const discountAmount = total - discountedAmount
        const taxedAmount = (discountAmount / purchaseOrderData?.billDetails?.tax) / 100;
        const netAmount = discountAmount + taxedAmount
        const reqObj = {
            billNo: purchaseOrderData?.billDetails?.billNo,
            // date: moment(purchaseOrderData?.billDetails?.billDate, "DD/MM/YYYY").toISOString(),
            POdate: purchaseOrderData?.billDetails?.POdate,
            dealerId: purchaseOrderData?.billDetails?.dealerId,
            dealerName: purchaseOrderData?.billDetails?.dealerName,
            discount: purchaseOrderData?.billDetails?.discount,
            isWarehouse: location === "WAREHOUSE D2" ? true : false,
            netAmount: netAmount,
            purchasePersonId: purchaseOrderData?.billDetails?.purchasePersonId,
            purchasePersonName: purchaseOrderData?.billDetails?.purchasePersonName,
            quantity: updatedPurchaseOrderData.reduce((acc, item) => acc + item.quantity, 0),
            tax: purchaseOrderData?.billDetails?.tax,
            totalAmount: total,
            poId: purchaseOrderData?.billDetails?.poId,
            purchases: updatedPurchaseOrderData,
        }
        // console.log("purchaseOrderData?.billDetails?.billDate", moment(purchaseOrderData?.billDetails?.billDate, "DD/MM/YYYY").format("YYYY-MM-DD"));


        dispatch(createPurchaseByPo(reqObj))
            .then((respo) => {
                if (respo.status === 200) {
                    message.success('Purchase Bill Created')
                } else {
                    message.error("Something went Wrong")
                }
            })
            .finally((respo) => {
                setTimeout(() => {
                    history.push("/dagaImpex/purchaseOrderGenerateBill")
                    window.location.reload();
                }, 300);
            })
    }

    const handleDelete = (id) => {
        setPurchaseId(prevPurchaseId =>
            prevPurchaseId.filter(itemId => itemId !== id)
        );

        const updatedData = updatedPurchaseOrderData.filter(item => item._id !== id);

        setUpdatedPurchaseOrderData(updatedData);

    };

    return (
        <>
            <Card>
                <Row>
                    <Col sm={24} md={16} lg={18}>
                        <h3 className="mb-4">Purchase Order Generate Bill</h3>
                    </Col>
                    <Col sm={24} md={8} lg={6} className="d-flex justify-content-end">
                        <Button onClick={() => {
                            history.push("/dagaImpex/purchaseOrderGenerateBill")
                        }}>
                            <ArrowLeftOutlined className="mr-2 mb-1" /> Back
                        </Button>
                    </Col>
                </Row>

                <Table
                    dataSource={purchaseOrderData?.purchaseOrder}
                    columns={columns}
                    bordered="true"
                    size="middle"
                    pagination={false}
                />
                {
                    console.log("updatedPurchaseOrderData ------------------------------>", updatedPurchaseOrderData)
                }

                {
                    updatedPurchaseOrderData && updatedPurchaseOrderData.length > 0
                        ?
                        <>
                            <div className="my-4">
                                <h3>Purchase List</h3>
                            </div>
                            <Table
                                dataSource={updatedPurchaseOrderData}
                                columns={columns2}
                                bordered="true"
                                size="middle"
                                pagination={false}
                            />
                            <Button className="mt-4 w-100" type="primary" onClick={handleGenerateBill}>Generate Bill</Button>
                        </>
                        :
                        <></>
                }

            </Card>
        </>
    );
};

export default PurchaseOrderGenerateBillList;
