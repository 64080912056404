import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Input, DatePicker, Button, message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOpeningStcok, updateStcok } from "../../../redux/actions/openingStockAction";
import BarcodeReader from "react-barcode-reader";
import TopBarProgress from "react-topbar-progress-indicator";
import BarcodeDetails from "./BarcodeDetails";
const dateFormat = "YYYY-MM-DD";

class BarcodeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barcode: "",
            quantity: "",
            MOQ: "",
            remark: ''
        };
        this.handleScan = this.handleScan.bind(this);
    }

    getbarcodeDetails = () => {
        let obj = { barcode: this.state.barcode }
        this.props.getOpeningStcok(obj)
    }

    onBarcodeChange = async (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onRemarkChange = async (event) => {
        this.setState({ remark: event.target.value });
    };
    async handleScan(data) {
        if (data.length == 9 || data.length == 7) {
            this.setState({
                barcode: data,
            });
            let obj = { barcode: data }
            this.props.getOpeningStcok(obj)
        }
    }
    stockUpdate = () => {
        const { MOQ } = this.props.barcodeDetails
        if (this.state.quantity % MOQ !== 0) {
            message.warn("Quantity should be in multiple of MOQ")
        } else {
            let userId = localStorage.getItem("userId")
            let reqBody = {
                barcode: this.state.barcode,
                quantity: this.state.quantity,
                userId: userId,
                remark: this.state.remark,
            }
            if (this.state.barcode && this.state.quantity) {
                this.props.updateStcok(reqBody)
            } else {
                message.error("Please Enter Quantity and Barcode!")
            }
        }
    }
    render() {
        return (
            <div className="subCategory-dashboard">
                {this.props.progressBarStatus && <TopBarProgress />}
                <div className="container-fluid category-list">
                    <Row className="title-row">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div className="page-header">Opening Stock</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form
                                name="add-Purchase"
                                className="add-Purchase"
                                onFinish={this.getbarcodeDetails}
                                initialValues={{ remember: true }}
                            >
                                <Row gutter={[16, 0]} className="mt-4">
                                    <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                                        <Tooltip title="Barcode">
                                            <Form.Item
                                                //name="barcode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please Enter Barcode Number !",
                                                    },
                                                ]}
                                            >
                                                <BarcodeReader
                                                    onError={this.handleError}
                                                    onScan={this.handleScan}
                                                />
                                                <Input
                                                    placeholder="Enter Barcode Number"
                                                    name="barcode"
                                                    value={this.state.barcode}
                                                    onChange={this.onBarcodeChange}
                                                    required
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="close-modal"
                                                block
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    {
                        this.props.barcodeDetails ? <>
                            <div className="card mt-4">
                                <div className="card-body">
                                    <BarcodeDetails barcodeDeatils={this.props?.barcodeDetails} />
                                    <Row className="mt-4">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form
                                                name="add-return-sale"
                                                className="add-return-sale"
                                                onFinish={this.stockUpdate}
                                                initialValues={{ remember: true }}
                                            >
                                                <Row gutter={[8, 0]}>
                                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                        <Tooltip title="Barcode">
                                                            <Form.Item
                                                                //name="barcode"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Barcode",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    placeholder="Barcode"
                                                                    name="barcode"
                                                                    value={this.state.barcode}
                                                                    disabled
                                                                />
                                                            </Form.Item>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                        <Tooltip title="Quantity">
                                                            <Form.Item
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Quantity",
                                                                    },
                                                                ]}
                                                            >
                                                                <BarcodeReader
                                                                    onError={this.handleError}
                                                                    onScan={this.handleScan}
                                                                />
                                                                <Input
                                                                    placeholder="Enter Quantity"
                                                                    name="quantity"
                                                                    value={this.state.quantity}
                                                                    onChange={this.onBarcodeChange}
                                                                    required
                                                                />
                                                            </Form.Item>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                        <Tooltip title="Remark">
                                                            <Form.Item
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Remark",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    placeholder="Enter Remark"
                                                                    name="remark"
                                                                    value={this.state.remark}
                                                                    onChange={this.onRemarkChange}
                                                                    required
                                                                />
                                                            </Form.Item>
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                        <Button
                                                            type="primary"
                                                            className="updatestock"
                                                            block
                                                            onClick={() => this.stockUpdate()}
                                                        >
                                                            Update Stock
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>

                                </div>
                                {console.log("check Details", this.props.barcodeDetails)}
                            </div>
                        </> : <>
                            <h1>hello   </h1>
                        </>
                    }

                    {/* <BarcodeReportList
            reports={this.props.reports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          /> */}
                </div>
            </div>
        );
    }
}
BarcodeReport.propTypes = {
    getOpeningStcok: PropTypes.func.isRequired,
    updateStcok: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    barcodeDetails: state.openingStockData.openingStock,
});

export default connect(mapStateToProps, {
    getOpeningStcok, updateStcok
})(BarcodeReport);
