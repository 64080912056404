import React, { Component } from "react";
import PropTypes, { element } from "prop-types";
import { connect } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import {
  Row,
  Col,
  Checkbox,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Alert,
  message,
  Spin
} from "antd";
import { getActiveandInactiveCustomer } from "../../../../redux/actions/customerAction";
import {
  getSalesBill,
  getSalesUsingBarcode,
  saleReturn,
  saleReturnDefected,
  deletesaleReturn,
  deletesaleReturnDefected,
  checkLastFiveBills

} from "../../../../redux/actions/saleAction";
import BarcodeReader from "react-barcode-reader";
import ShowSalesReturnDetails from "./ShowSalesReturnDetails";
import { StopOutlined, LoadingOutlined } from "@ant-design/icons";
import AddedListReturn from "./AddedListReturn";
import { uuid } from "uuidv4";
import AddListReturnDefected from "./AddListReturnDefected";
import moment from "moment";
import { getBrands } from "../../../../redux/actions/brandAction";
import AddedListReturNewnDefective from "./AddedListReturnNewDefective";
let deleteCount = 0
const { Option } = Select;
const dateFormat = "DD/MM/YYYY";
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
class AddSalesReturn extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    const currentDate = moment().format("DD/MM/YYYY");
    const currentTime = moment().format("HH:mm:ss");
    this.state = {
      saveDisableStatus: false,
      salesReturnData: [],
      showReturnListData: [],
      customerName: "",
      customerId: "",
      barcode: "",
      returnType: null,
      maxReturnQty: "",
      quantity: "",
      totalBoxes: "",
      totalAmount: "",
      totalQuantity: "",
      brand: "",
      amount: "",
      uuID: "",
      MOQ: "",
      pd: "",
      id: "",
      remark1: "",
      remark2: "",
      disableField: false,
      isUpdated: false,
      saleReturnBillID: "",
      saleReturnDefectedBillID: "",
      wsp: '',
      color: "",
      size: '',
      brand: '',
      designNumber: '',
      billDate: "",
      saleReturnbillDate: moment(`${currentDate} ${currentTime}`, 'DD/MM/YYYY HH:mm:ss').toDate(),
      defectiveQuantity: ""
    };
    this.handleScan = this.handleScan.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  onChange(event) {
    if (event.target.name === "quantity" && this.state.quantity) {
      if (event.target.value > this.props?.salesBillsBarcode?.quantity) {
        alert("Can not input quantity greater then sale quantity");
        return this.setState({
          quantity: this.props?.salesBillsBarcode?.quantity,
        });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  }
  getData = (name) => {
    if (name == "customer") this.props.getActiveandInactiveCustomer("STORE_USER");
    if (name == "brand") this.props.getBrands();
  };


  onBarcodeChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.customerId !== "") {
      if (event.target.value.length == 9) {
        await this.props.getSalesUsingBarcode(
          event.target.value,
          this.state.customerId
        );
        if (this.props?.salesBillsBarcode?.quantity <= 0) {
          message.warn("Out of Stock")
        } else {
          this.setState({
            quantity: this.props?.salesBillsBarcode?.MOQ,
            invoiceId: this.props?.salesBillsBarcode?.invoiceId,
            maxReturnQty: this.props?.salesBillsBarcode?.quantity,
            MOQ: this.props?.salesBillsBarcode?.MOQ,
            wsp: this.props?.salesBillsBarcode?.purchaseDetails?.wsp,
            color: this.props?.salesBillsBarcode?.purchaseDetails?.color,
            size: this.props?.salesBillsBarcode?.purchaseDetails?.size,
            brand: this.props?.salesBillsBarcode?.purchaseDetails?.brand,
            designNumber: this.props?.salesBillsBarcode?.purchaseDetails?.designNumber,
            billDate: this.props?.salesBillsBarcode?.purchaseDetails?.billDate,
            amount: this.props?.salesBillsBarcode?.purchaseDetails?.wsp,
          });
          this.formRef.current.setFieldsValue({
            brand: this.props?.salesBillsBarcode?.purchaseDetails?.brand,
          });
        }
      }

    } else {
      return alert("Select Customer");
    }
  };


  async handleScan(data) {
    if (data.length == 9 || data.length == 9) {
      await this.props.getSalesUsingBarcode(data, this.state.customerId);
    }
  }
  handleChangeCustomer = (key, value) => {
    let customerId = value.value;
    let customerName = value.name;
    this.setState({
      customerId: customerId,
      customerName: customerName,
    });
    this.props.getSalesBill(customerId);
    // let obj = {
    //   customerId : customerId
    // }
    // this.props.checkLastFiveBills(obj)
  };

  handleChangeBrand = (key, value) => {
    let brandName = value.name;
    this.setState({
      brand: brandName,
    });

  };

  onDateChange = (date, dateString) => {

    let currentTime = moment().format('HH:mm:ss');
    let d = moment(dateString + ' ' + currentTime, 'DD/MM/YYYY HH:mm:ss').toDate();
    this.setState({ saleReturnbillDate: d });
  }


  handleReturnChange = (key, value) => {

    this.setState({
      returnType: key,
    });
  };

  createSalesReturn = () => {
    this.setState({ saveDisableStatus: true })
    let userId = localStorage.getItem("userId")
    let obj = {
      salesReturnData: this.state.showReturnListData,
      isUpdated: this.state.isUpdated,
      saleReturnBillID: this.state.saleReturnBillID,
      remark1: this.state.remark1,
      remark2: this.state.remark2,
      userId: userId,
      date: this.state.saleReturnbillDate,
      customerId: this.state.customerId
    }
    let obj2 = {
      salesReturnData: this.state.showReturnListData,
      isUpdated: this.state.isUpdated,
      saleReturnDefectedBillID: this.state.saleReturnDefectedBillID,
      remark1: this.state.remark1,
      remark2: this.state.remark2,
      invoiceId: this.state.showReturnListData[0].invoiceId,
      userId: userId,
      date: this.state.saleReturnbillDate,
      customerId: this.state.customerId
      // saleReturnBillID:this.state.saleReturnBillID
    }
    if (this.state.returnType === "1") {
      this.props.saleReturn(obj).then((response) => {
        if (response.state === 200) {
          this.setState({ saveDisableStatus: false })
        }
      }).catch((error) => console.log("error", error)).finally(() =>
        setTimeout(() => {
          window.location.reload(0)
        }, 1000)
      )
    } else {
      this.props.saleReturnDefected(obj2).then((response) => {
        if (response.state === 200) {
          this.setState({ saveDisableStatus: false })
        }
      }).catch((error) => console.log("error", error)).finally(() =>
        setTimeout(() => {
          window.location.reload(0)
        }, 2000)
      )
    }
  };


  addSalesReturn = () => {
    this.setState({ disableField: true });
    if (this.state.quantity % this.props.salesBillsBarcode.MOQ != 0) {
      message.warn("add quantity only in multiple of MOQ ")
      return this.setState({
        quantity: this.props?.salesBillsUser?.quantity
      });
    } else {

      this.setState({ disableField: true });
      if (this.state.returnType === "1") {
        let returnObj = {
          id: this.props.salesBillsBarcode._id,
          quantity: this.state.quantity,
          invoiceId: this.state.invoiceId,
          barcode: this.state.barcode,
          customerName: this.state.customerName,
          maxReturnQty: this.state.maxReturnQty,
          MOQ: this.state.MOQ,
          wsp: this.state.wsp,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
          customerId: this.state.customerId,
          billDate: this.state.billDate,
        };
        this.setState({
          salesReturnData: returnObj,
          ...this.state.salesReturnData
        })
        //  this.state.salesReturnData.push(returnObj);
        //TABLE
        let showReturnDataObj = {
          id: this.props.salesBillsBarcode._id,
          quantity: this.state.quantity,
          invoiceId: this.state.invoiceId,
          barcode: this.state.barcode,
          customerName: this.state.customerName,
          maxReturnQty: this.state.maxReturnQty,
          MOQ: this.state.MOQ,
          wsp: this.state.wsp,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
          customerId: this.state.customerId,
          billDate: this.state.billDate,
        };

        this.setState({
          salesReturnData: [
            showReturnDataObj,
            ...this.state.showReturnListData,
          ],
        });
        // this.props.saleReturn({ salesReturnData: this.state.showReturnListData }, this.props.history);
        this.setState({
          barcode: "",
          quantity: "",
          maxReturnQty: "",
          invoiceId: "",
          brand: "",
          amount: "",
          MOQ: "",
          wsp: "",
          color: "",
          size: "",
          brand: "",
          designNumber: ""
        });

        // this.formRef.current.setFieldsValue({
        //   customerName: undefined,
        // });
      } else {
        let returnDefectedObj = {
          brand: this.state.brand,
          quantity: this.state.quantity,
          maxReturnQty: this.state.maxReturnQty,
          amount: this.state.amount,
          userId: this.state.customerId,
          MOQ: this.state.MOQ,
          barcode: this.state.barcode,
          wsp: this.state.wsp,
          invoiceId: this.state.invoiceId,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
          customerId: this.state.customerId,
          billDate: this.state.billDate,
        };
        // this.setState({
        //   showReturnListData:returnDefectedObj,
        //   ...this.state.showReturnListData
        // })
        this.setState({
          salesReturnData: returnDefectedObj,
          ...this.state.salesReturnData
        })
        // this.state.salesReturnData.push(returnDefectedObj);

        //TABLE
        let showReturnDataDefctiveObj = {
          brand: this.state.brand,
          quantity: this.state.quantity,
          maxReturnQty: this.state.maxReturnQty,
          amount: this.state.amount,
          customerName: this.state.customerName,
          userId: this.state.customerId,
          MOQ: this.state.MOQ,
          barcode: this.state.barcode,
          wsp: this.state.wsp,
          invoiceId: this.state.invoiceId,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
          customerId: this.state.customerId,
          billDate: this.state.billDate,
        };
        // this.setState({
        //   showReturnListData: [
        //     showReturnDataDefctiveObj,
        //     ...this.state.showReturnListData,
        //   ],
        // });
        this.setState({
          salesReturnData: [
            showReturnDataDefctiveObj,
            ...this.state.showReturnListData,
          ],
        });
        this.setState({
          barcode: "",
          quantity: "",
          maxReturnQty: "",
          brand: "",
          amount: "",
          userId: "",
          MOQ: "",
          wsp: "",
          color: "",
          size: "",
          brand: "",
          designNumber: "",
        });
        // this.formRef.current.setFieldsValue({
        //   customerName: undefined,
        // });
      };


      //for checking existing obj {dg}
      const existingObjIndex = this.state.showReturnListData.findIndex(obj => obj.barcode === this.state.barcode);
      if (existingObjIndex >= 0) {
        const existingObj = this.state.showReturnListData[existingObjIndex];

        if (Number(this.state.quantity) + Number(existingObj.quantity) <= Number(existingObj.maxReturnQty)) {
          const updatedObj = { ...existingObj, quantity: Number(existingObj.quantity) + Number(this.state.quantity) };
          const updatedListData = this.state.salesReturnData;
          updatedListData[existingObjIndex] = updatedObj;

          const updatedListData1 = this.state.showReturnListData;
          updatedListData1[existingObjIndex] = updatedObj;
          this.setState({
            //  showReturnListData: [updatedListData,
            //   ...this.state.showReturnListData

            // ],
            //  salesReturnData:[updatedListData,
            //   ...this.state.salesReturnData]
            showReturnListData: updatedListData,
            salesReturnData: updatedListData1,
          });
        } else {
          message.warn("cannot Add more than maximum Quantity")
          this.setState({
            showReturnListData: this.state.showReturnListData,
            salesReturnData: this.state.salesReturnData,
          })
        }
        // this.setState({
        //   showReturnListData : this.state.salesReturnData,
        //   salesReturnData : this.state.showReturnListData
        // })
      } else {
        if (this.state.returnType === "1") {
          const newObj = {
            quantity: this.state.quantity,
            invoiceId: this.state.invoiceId,
            id: this.props.salesBillsBarcode._id,
            barcode: this.state.barcode,
            customerName: this.state.customerName,
            maxReturnQty: this.state.maxReturnQty,
            MOQ: this.state.MOQ,
            wsp: this.state.wsp,
            color: this.state.color,
            size: this.state.size,
            brand: this.state.brand,
            designNumber: this.state.designNumber,
            billDate: this.state.billDate,
          };

          this.setState({
            showReturnListData: [newObj,
              ...this.state.showReturnListData,
            ],
          });
        }
        else {
          const newRDObj = {
            brand: this.state.brand,
            quantity: this.state.quantity,
            maxReturnQty: this.state.maxReturnQty,
            amount: this.state.amount,
            customerName: this.state.customerName,
            userId: this.state.customerId,
            MOQ: this.state.MOQ,
            barcode: this.state.barcode,
            wsp: this.state.wsp,
            color: this.state.color,
            size: this.state.size,
            brand: this.state.brand,
            designNumber: this.state.designNumber,
            invoiceId: this.state.invoiceId,
            billDate: this.state.billDate,
          };
          this.setState({
            showReturnListData: [newRDObj,
              ...this.state.showReturnListData
            ],
          });
        }
      }

    };


  }

  addDefectiveSalesReturn = () => {
    this.setState({ disableField: true });
    if (this.state.quantity && this.state.amount && this.state.brand && this.state.customerName) {

      let returnDefectedObj = {
        brand: this.state.brand,
        quantity: this.state.quantity,
        maxReturnQty: this.state.maxReturnQty ? this.state.maxReturnQty : 0,
        amount: this.state.amount,
        userId: this.state.customerId,
        MOQ: this.state.MOQ ? this.state.MOQ : 0,
        barcode: this.state.barcode ? this.state.barcode : 0,
        wsp: this.state.wsp ? this.state.wsp : 0,
        invoiceId: this.state.invoiceId ? this.state.invoiceId : 0,
        color: this.state.color ? this.state.color : "",
        size: this.state.size ? this.state.size : "",
        designNumber: this.state.designNumber ? this.state.designNumber : "",
        deleteCount: deleteCount,
        billDate: this.state.billDate,
      };
      // this.setState({
      //   showReturnListData:returnDefectedObj,
      //   ...this.state.showReturnListData
      // })
      this.setState({
        salesReturnData: returnDefectedObj,
        ...this.state.salesReturnData
      })
      // this.state.salesReturnData.push(returnDefectedObj);

      //TABLE
      let showReturnDataDefctiveObj = {
        brand: this.state.brand,
        quantity: this.state.quantity,
        maxReturnQty: this.state.maxReturnQty ? this.state.maxReturnQty : 0,
        amount: this.state.amount,
        userId: this.state.customerId,
        MOQ: this.state.MOQ ? this.state.MOQ : 0,
        barcode: this.state.barcode ? this.state.barcode : 0,
        wsp: this.state.wsp ? this.state.wsp : 0,
        invoiceId: this.state.invoiceId ? this.state.invoiceId : 0,
        color: this.state.color ? this.state.color : "",
        size: this.state.size ? this.state.size : "",
        designNumber: this.state.designNumber ? this.state.designNumber : "",
        deleteCount: deleteCount,
        billDate: this.state.billDate,
      };
      // this.setState({
      //   showReturnListData: [
      //     showReturnDataDefctiveObj,
      //     ...this.state.showReturnListData,
      //   ],
      // });
      this.setState({
        salesReturnData: [
          showReturnDataDefctiveObj,
          ...this.state.showReturnListData,
        ],
      });
      this.setState({
        barcode: "",
        quantity: "",
        maxReturnQty: "",
        // brand: "",
        amount: "",
        userId: "",
        MOQ: "",
        wsp: "",
        color: "",
        size: "",
        // brand: "",
        designNumber: "",
      });
      // this.formRef.current.setFieldsValue({
      //   customerName: undefined,
      // });


      const newRDObj = {
        brand: this.state.brand,
        quantity: this.state.quantity,
        maxReturnQty: this.state.maxReturnQty ? this.state.maxReturnQty : 0,
        amount: this.state.amount,
        customerName: this.state.customerName,
        userId: this.state.customerId,
        MOQ: this.state.MOQ ? this.state.MOQ : 0,
        barcode: this.state.barcode ? this.state.barcode : 0,
        wsp: this.state.wsp ? this.state.wsp : 0,
        color: this.state.color ? this.state.color : "",
        size: this.state.size ? this.state.size : "",
        designNumber: this.state.designNumber ? this.state.designNumber : "",
        invoiceId: this.state.invoiceId ? this.state.invoiceId : "",
        deleteCount: deleteCount,
        billDate: this.state.billDate,
      };
      this.setState({
        showReturnListData: [newRDObj,
          ...this.state.showReturnListData
        ],
      });
    } else {
      message.error("Please Fill All Details")
    };
    deleteCount++
  }

  checkBarcodeExists = (barcode) => {
    let exist = this.state.showReturnListData.findIndex(
      (element) => element.barcode === barcode
    );
    return exist;
  };


  deleteSale = (id) => {
    let temp = this.state.showReturnListData
    const temp1 = temp.filter(obj => obj.id !== id)
    this.setState({
      showReturnListData: temp1,
      salesReturnData: temp1
    })
  }

  deleteDefectiveSale = (text) => {
    let temp = this.state.showReturnListData
    const temp1 = temp.filter(obj => obj.deleteCount !== text.deleteCount)
    this.setState({
      showReturnListData: temp1,
      salesReturnData: temp1
    })
    // if(text.barcode){
    //   let temp = this.state.showReturnListData
    //   const temp1 = temp.filter(obj => obj.barcode !== text.barcode)
    //   this.setState({
    //     showReturnListData: temp1,
    //     salesReturnData: temp1
    //   })
    // }else{
    //   let temp = this.state.showReturnListData
    //   const temp1 = temp.filter(obj => obj.deleteCount !== text.deleteCount ) 
    //   this.setState({
    //     showReturnListData: temp1,
    //     salesReturnData: temp1
    //   })
    // }
  }

  editDefectiveReturn = (text) => {
    this.setState({
      barcode: text.barcode ? text.barcode : "",
      quantity: text.quantity,
      maxReturnQty: text.maxReturnQty,
      brand: text.brand,
      amount: text.amount,
      userId: text.userId,
      MOQ: text.MOQ,
      wsp: text.wsp,
      color: text.color,
      size: text.size,
      designNumber: text.designNumber,
    });
    this.formRef.current.setFieldsValue({
      brand: text.brand,
    });
  }




  onRemoveQnty = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = this.state.showReturnListData;
    let returnTemp = this.state.salesReturnData;

    if (temp[index].quantity > temp[index].MOQ) {
      temp[index].quantity = Number(temp[index].quantity) - Number(temp[index].MOQ);
      returnTemp[index].quantity = Number(returnTemp[index].quantity) //- Number(temp[index].MOQ);
    } else {
      message.warn("Minimum quantity reached!")
    }


    this.setState({
      showReturnListData: temp,
      salesReturnData: returnTemp,
      pd: uuid(),
    });
  };
  onAddQnty = async (obj) => {
    if (this.state.returnType === "1") {

      if (obj.saleReturnId) {

        let index = this.checkBarcodeExists(obj.barcode);
        let saleData = await this.props.getSalesUsingBarcode(obj.barcode, obj.userId)
        this.setState({ maxReturnQty: this.props?.salesBillsBarcode?.totalSalesQuantity, })
        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;

        if (Number(temp[index].quantity) + 1 <= Number(this.state.maxReturnQty)) {



          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);

          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }

      } else {

        let index = this.checkBarcodeExists(obj.barcode);

        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;


        if (Number(temp[index].quantity) + 1 <= Number(temp[index].maxReturnQty)) {


          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);

          returnTemp[index].quantity = Number(temp[index].quantity) //+ Number(temp[index].MOQ);

          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }

      }
    } else {
      if (obj.saleReturnId) {
        let index = this.checkBarcodeExists(obj.barcode);
        let saleData = await this.props.getSalesUsingBarcode(obj.barcode, obj.userId)
        this.setState({ maxReturnQty: this.props?.salesBillsBarcode?.totalSalesQuantity, })
        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;
        if (Number(temp[index].quantity) + 1 <= Number(this.state.maxReturnQty)) {
          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(temp[index].quantity) //+ Number(temp[index].MOQ);
          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }
      } else {
        let index = this.checkBarcodeExists(obj.barcode);
        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;
        if (Number(temp[index].quantity) + 1 <= temp[index].maxReturnQty) {
          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(temp[index].quantity) //+ Number(temp[index].MOQ);
          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }
      }
    }
  };
  deletesaleReturn = (_id) => {
    this.props.deletesaleReturn(_id)
    let temp = this.state.showReturnListData
    temp = temp.filter(obj => obj._id !== _id)
    let returnTemp = this.state.salesReturnData
    returnTemp = returnTemp.filter(obj => obj._id !== _id)

    this.setState({
      showReturnListData: temp,
      salesReturnData: returnTemp,
      // pd: uuid(),
    });
  };

  deletesaleReturnDefected = (_id) => {
    this.props.deletesaleReturnDefected(_id)

    let temp = this.state.showReturnListData

    temp = temp.filter(obj => obj._id !== _id)
    let returnTemp = this.state.salesReturnData
    returnTemp = returnTemp.filter(obj => obj._id !== _id)

    this.setState({
      showReturnListData: temp,
      salesReturnData: returnTemp,
      // pd: uuid(),
    });
  };

  // deletesaleReturnDefected = (_id) => {
  //   // console.log("delete id---", _id)

  //   this.props.deletesaleReturnDefected(_id)

  //   let temp = this.state.showReturnListData

  //   temp = temp.filter(obj => obj._id !== _id)
  //   let returnTemp = this.state.salesReturnData
  //   returnTemp = returnTemp.filter(obj => obj._id !== _id)

  //   this.setState({
  //     showReturnListData: temp,
  //     salesReturnData: returnTemp,
  //     // pd: uuid(),
  //   }); 
  // };

  componentWillUnmount = () => {
    this.setState({
      saveDisableStatus: false
    })
  }


  componentDidUpdate = (preProps, preState) => {
    if (preState.quantity != this.state.quantity) {
      if (this.state.barcode && this.state.quantity && this.state.returnType == "1") {
        this.addSalesReturn()
      }
    }
  }

  //for history
  componentDidMount() {
    if (
      this.props.history.location.state == undefined ||
      this.props.history.location.state == null
    )
      return null;
    if (
      this.props.history.location.state != undefined ||
      this.props.history.location.state != null
    ) {
      // this.setState({
      //   updateBill: this.props.history.location.state.updateBill,
      // });
      if (this.props.history.location.state.saleReturnObj) {
        let saleReturnObj = this.props.history.location.state.saleReturnObj;

        if (saleReturnObj) {
          this.setState({ update: true });
          this.setState({ isUpdated: true })
        }
        if (this.props.history.location.state.type === 'Normal') {
          this.setState({ returnType: '1' })
        }

        // this.setState({ tempSaleObj: saleObj });
        let data = saleReturnObj[1];
        let billData = saleReturnObj[0];
        this.setState({
          salesBillsUser: saleReturnObj,
          pd: uuid(),
        })
        let e = 0;
        let f = data.length;
        let finalSaleReturnData = [];
        if (saleReturnObj[0] && saleReturnObj[0].remark1) {
          this.setState({ remark1: saleReturnObj[0].remark1 })
        }

        if (saleReturnObj[0] && saleReturnObj[0].remark2) {
          this.setState({ remark2: saleReturnObj[0].remark2 })
        }

        while (e < f) {
          // data[e].details.boxes = data[e].quantity / data[e].details.MOQ;
          //data[e].details.saleReturnQty = data[e].quantity;
          data[e].details._id = data[e]._id
          data[e].details.quantity = data[e].quantity;
          data[e].details.created_at = billData.date
          data[e].details.barcode = data[e].barcode
          data[e].details.invoiceId = data[e].invoiceId

          data[e].details.MOQ = data[e].MOQ;
          data[e].details.totalBoxes = billData.totalBoxes;
          // data[e].details.finalAmount = data[e].details.MOQ * data[e].details.wsp;
          // data[e].details.salesPersonId = data[e].salesPersonId;
          data[e].details.saleReturnId = data[e].saleReturnId;
          data[e].details.price = data[e].price
          data[e].details.invoiceId = data[e].invoiceId;
          data[e].details.barcode = data[e].barcode;
          // =============
          data[e].details.designNumber = data[e].purchaseDetails.designNumber;
          data[e].details.brand = data[e].purchaseDetails.brand;
          data[e].details.size = data[e].purchaseDetails.size;
          data[e].details.color = data[e].purchaseDetails.color;
          data[e].details.wsp = data[e].purchaseDetails.wsp;
          // data[e].details.created_at = billData[e].created_at
          // billData[e].totalBoxes= billData[e].totalBoxes;
          // billData[e].totalQuantity= billData[e].totalQuantity;
          // billData[e].totalAmount= billData[e].totalAmount;
          // data[e].details.maxReturnQty = this.state.maxReturnQty;

          finalSaleReturnData.push(data[e].details);
          ++e;
        }
        this.props.getSalesBill(billData.userId._id);

        this.setState({
          showReturnListData: finalSaleReturnData,
          // salesBillsUser:finalSaleReturnData,
          salesReturnData: finalSaleReturnData,
          MOQ: billData.MOQ ? billData.MOQ : "",
          totalBoxes: billData.totalBoxes,
          totalQuantity: billData.totalQuantity,
          totalAmount: billData.totalAmount,
          saleReturnBillID: billData._id,

          invoiceNumber: data.invoiceId,
          customerName: billData.userId.name,
          customerId: billData.userId._id,
          saleReturnId: data.saleReturnId,
          isUpdated: true,
          // agentId: agentIdSalesOrder,
          // agent: agentSalesOrder,
          salesPersonId: data.salesPersonId,
          isUpdated: true,
          pd: uuid(),

        });



        this.formRef.current.setFieldsValue({
          customerName: billData.userId.name,

        });
        // // temp = [this.state.selectedData, ...this.state.myPurchaseData];

      }
      else {

        let saleReturnDefectiveObj = this.props.history.location.state.saleReturnDefectedObj;
        if (this.props.history.location.state.type === 'Defective') {
          this.setState({ returnType: '2' })
        }
        if (saleReturnDefectiveObj) {
          this.setState({ update: true });
          this.setState({ isUpdated: true });
        }
        // this.setState({ tempSaleObj: saleObj });
        let data = saleReturnDefectiveObj[1];
        let billData = saleReturnDefectiveObj[0];
        this.setState({
          salesBillsUser: saleReturnDefectiveObj,
          pd: uuid(),
        })
        let e = 0;
        let f = data.length;
        let finalSaleReturnData = [];
        while (e < f) {
          // data[e].details.boxes = data[e].quantity / data[e].details.MOQ;
          //data[e].details.saleReturnQty = data[e].quantity;
          data[e].details._id = data[e]._id
          data[e].details.quantity = data[e].quantity;
          data[e].details.created_at = billData.created_at
          data[e].details.brand = data[e].brand
          data[e].details.customerName = billData.userId.name
          data[e].details.saleReturnDefectedQnt = data[e].quantity


          data[e].details.saleReturnId = data[e].saleReturnId;
          data[e].details.amount = data[e].amount
          data[e].details.MOQ = data[e].MOQ
          // data[e].details.invoiceId = data[e].invoiceId;
          data[e].details.barcode = data[e].barcode;
          // =============
          data[e].details.designNumber = data[e].purchaseDetails.designNumber;
          data[e].details.brand = data[e].purchaseDetails.brand;
          data[e].details.size = data[e].purchaseDetails.size;
          data[e].details.color = data[e].purchaseDetails.color;
          data[e].details.wsp = data[e].purchaseDetails.wsp;


          finalSaleReturnData.push(data[e].details);
          ++e;
        }

        this.setState({
          showReturnListData: finalSaleReturnData,
          // salesBillsUser:finalSaleReturnData,
          salesReturnData: finalSaleReturnData,


          totalQuantity: billData.totalQuantity,
          totalAmount: billData.totalAmount,
          saleReturnDefectedBillID: billData._id,


          customerName: billData.userId.name,
          customerId: billData.userId._id,
          saleReturnId: data.saleReturnId,
          isUpdated: true,

          pd: uuid(),

        });



        this.formRef.current.setFieldsValue({
          customerName: billData.userId.name,
          // returnType : 2

        });



      }

      return this.props.history.replace({ state: null });
    }
  }
  render() {
    // const currentDatee = moment().format("DD/MM/YYYY");
    // const currentTime = moment().format("HH:mm:ss");
    // var currentDate = moment(this.state.billDate)
    // const dateObject = currentDate.format('DD/MM/YYYY');
    let customerOptions = this.props.customers?.data?.map((customer) => {
      return (
        <Option name={customer.name} value={customer._id}>
          {customer.name}
        </Option>
      );
    });

    let brandOptions = this.props.brands.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });

    var disabled = false;
    if (this.state.disableField) {
      disabled = true;
    }
    return (
      <div className="add-purchase ">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid">
          {this.state.saveDisableStatus ? (
            <Spin indicator={antIcon} />
          ) : (<>
            <Row className="title-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="page-header">{this.state.isUpdated ? "Update Sales Return" : "Add Sales Return"}</div>
              </Col>
            </Row>
            <div className="card mt-4">
              <div className="card-body">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form
                      ref={this.formRef}
                      name="add-return-sale"
                      className="add-return-sale"
                      onFinish={this.showDataOnList}
                      initialValues={{ remember: true }}
                    >
                      <Row gutter={[16, 0]}>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Return Type">
                            <Select
                              disabled={disabled || this.state.update}
                              //disabled={disabled}
                              value={this.state.returnType}
                              style={{ width: "100%" }}
                              showSearch
                              placeholder="Select Return Type"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleReturnChange}
                            // value={this.state.update ? "1" : undefined}

                            >
                              <Option value="1">Normal</Option>
                              <Option value="2">Defective</Option>
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Select Customer">
                            <Form.Item
                              name="customerName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Customer Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={disabled}
                                style={{ width: "100%" }}
                                showSearch
                                placeholder="Select Customer"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={this.handleChangeCustomer}
                                onClick={() => this.getData("customer")}
                                onInputKeyDown={() => this.getData("customer")}
                              >
                                {customerOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Bill Date">
                            <Form.Item
                              name="billDate"
                              rules={[
                                {
                                  required: false,
                                  message: "Please Select Bill Date",
                                },
                              ]}
                            >
                              {/* <DatePicker
                              disabled={true}
                              // onChange={this.onDateChange}
                              placeholder="Bill Date"
                              value={moment(dateObject != "Invalid date" ? dateObject : currentDatee, dateFormat)}
                              format={dateFormat}
                            /> */}
                              <DatePicker
                                onChange={this.onDateChange}
                                placeholder="Bill Date"
                                defaultValue={moment(this.state.saleReturnbillDate, dateFormat)}
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Barcode">
                            <Form.Item
                              name="barcode"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Barcode Number !",
                                },
                              ]}
                            >

                              <BarcodeReader
                                onError={this.handleError}
                                onScan={this.handleScan}
                              />
                              <Input
                                placeholder="Enter Barcode Number"
                                name="barcode"
                                value={this.state.barcode}
                                onChange={this.onBarcodeChange}
                                required
                                disabled={this.state.isUpdated ? true : false}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Customer Remark">
                            <Form.Item>
                              <Input
                                placeholder="Enter Customer Remark"
                                name="remark1"
                                type="text"
                                value={this.state.remark1}
                                onChange={this.onChange}
                              // required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Store Remark">
                            <Form.Item>
                              <Input
                                placeholder="Enter Store Remark"
                                name="remark2"
                                type="text"
                                value={this.state.remark2}
                                onChange={this.onChange}
                              // required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Form>
                    {/* <ShowSalesReturnDetails
                    salesBillsUser={this.props.salesBillsUser}
                  /> */}
                    <Form
                      ref={this.formRef}
                      name="add-return"
                      className="add-return"
                      // onFinish={this.addSalesReturn}
                      initialValues={{ remember: true }}
                    >
                      {/* <Row gutter={[16, 0]} className="mt-4">
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Tooltip title=" Invoice Id">
                          <Form.Item>
                            <Input
                              placeholder=" Invoice Id"
                              value={this.state.invoiceId}
                              disabled
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Tooltip title="Remark">
                          <Form.Item>
                            <Input
                              placeholder="Enter Remark"
                              name="remark1"
                              type="text"
                              value={this.state.remark1}
                              onChange={this.onChange}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Tooltip title="Quantity">
                          <Form.Item>
                            <Input
                              onWheel={(e) => e.target.blur()}
                              placeholder="Enter Quantity Number"
                              name="quantity"
                              type="number"
                              value={this.state.quantity}
                              onChange={this.onChange}
                              onBlur={this.handleBlur}
                              min="0"
                              step="1"
                              max={this.props?.salesBillsBarcode?.quantity}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row> */}
                      {(() => {
                        if (this.state.returnType == "2") {
                          return (
                            <Row gutter={[16, 0]}>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Tooltip title="Enter Brand">

                                  <Form.Item
                                    name="brand"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Select Brand Name !",
                                      },
                                    ]}
                                  >
                                    <Select
                                      disabled={this.state.isUpdated ? true : false}
                                      style={{ width: "100%" }}
                                      showSearch
                                      placeholder="Select Brand"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      filterSort={(optionA, optionB) =>
                                        optionA.children
                                          .toLowerCase()
                                          .localeCompare(optionB.children.toLowerCase())
                                      }
                                      onChange={this.handleChangeBrand}
                                      onClick={() => this.getData("brand")}
                                      onInputKeyDown={() => this.getData("brand")}
                                    >
                                      {brandOptions}
                                    </Select>
                                  </Form.Item>
                                </Tooltip>
                              </Col>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Tooltip title="Amount ">
                                  <Form.Item>
                                    <Input
                                      min="0"
                                      step="1"
                                      type="number"
                                      placeholder="Price of one quantity"
                                      name="amount"
                                      value={this.state.amount}
                                      onChange={this.onChange}
                                      disabled={this.state.isUpdated ? true : false}
                                    />
                                  </Form.Item>
                                </Tooltip>
                              </Col>

                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>

                                <Tooltip title="Quantity">
                                  <Form.Item>
                                    <Input
                                      min="0"
                                      step="1"
                                      type="number"
                                      placeholder="No of Quantity"
                                      name="quantity"
                                      value={this.state.quantity}
                                      onChange={this.onChange}
                                      disabled={this.state.isUpdated ? true : false}
                                    />
                                  </Form.Item>
                                </Tooltip>
                              </Col>
                            </Row>
                          );
                        }
                      })()}

                      <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          {
                            this.state.returnType === "2" && this.state.barcode == "" ? <>
                              {
                                this.state.quantity == 0 ? <>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                    onClick={() => message.warn("Can not return 0 quantity")}
                                  >
                                    Add Return
                                  </Button>

                                </> : <>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                    onClick={this.addDefectiveSalesReturn}
                                    disabled={this.state.update ? true : false}
                                  >
                                    Add Return
                                  </Button>
                                </>
                              }
                            </> : <>
                              {
                                this.state.quantity == 0 ? <>
                                  {/* <Button
                                type="primary"
                                htmlType="submit"
                                className="close-modal"
                                block
                                onClick={() => message.warn("Can not return 0 quantity")}
                              // disabled={this.state.update ? true : this.state.brand && this.state.amount ? false : true}
                              >
                                Add Return
                              </Button> */}
                                </> : <>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                    onClick={this.addDefectiveSalesReturn}
                                    disabled={this.state.update ? true : this.state.brand && this.state.amount ? false : true}
                                  >
                                    Add Return
                                  </Button>
                                </>
                              }
                            </>
                          }
                        </Col>
                      </Row>
                    </Form>
                    {(() => {
                      if (this.state.returnType == "1") {
                        if (this.state.update) {
                          return (
                            <AddedListReturn
                              onAddQnty={this.onAddQnty}
                              onRemoveQnty={this.onRemoveQnty}
                              deleteSale={this.deleteSale}
                              key={this.state.pd}
                              // data={this.state.showReturnListData}
                              data={this.state.salesReturnData}
                              deletesaleReturn={this.deletesaleReturn}
                            />
                          );
                        } else {
                          return (
                            <AddedListReturn
                              onAddQnty={this.onAddQnty}
                              onRemoveQnty={this.onRemoveQnty}
                              deleteSale={this.deleteSale}
                              key={this.state.pd}
                              data={this.state.showReturnListData}

                              // data={this.state.salesReturnData}
                              deletesaleReturn={this.deletesaleReturn}

                            />
                          );
                        }

                      } else if (this.state.returnType == "2") {
                        return (
                          <AddedListReturNewnDefective
                            data={this.state.showReturnListData}
                            onAddQnty={this.onAddQnty}
                            onRemoveQnty={this.onRemoveQnty}
                            deleteSale={this.deleteDefectiveSale}
                            deletesaleReturnDefected={this.deleteDefectiveSale}
                            editDefectiveReturn={this.editDefectiveReturn}
                            key={this.state.pd}
                          />
                        );
                      }
                    })()}
                    <Row gutter={[8, 0]} className="mt-3">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {(() => {
                          if (this.state.salesReturnData.length === 0) {
                            return (
                              <Button
                                type="primary"
                                className="save-modal text-white"
                                disabled
                                block
                              >
                                Create Sales Return <StopOutlined />
                              </Button>
                            );
                          } else {
                            if (this.state.update) {
                              return (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="save-modal"
                                  block
                                  onClick={this.createSalesReturn}
                                >
                                  Update Sales Return
                                </Button>
                              );
                            } else {
                              return (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="save-modal"
                                  block
                                  disabled={this.state.saveDisableStatus}
                                  onClick={this.createSalesReturn}
                                >
                                  Create Sales Return
                                </Button>
                              );
                            }
                          }
                        })()}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {
                  this.state.salesReturnData?.length > 0
                    ?
                    <div className=" mt-4">
                      <div className="col-md-12 ml-auto">
                        <div className="grand-totall">
                          <div className="title-wrap">
                            <h4 className="cart-bottom-title section-bg-gary-cart pt-4">
                              Add Sales Return Total
                            </h4>
                          </div>
                          {/* <h5>
                        Total Barcodes{" "}
                        <span>
                          {this.state.salesReturnData?.length || 0}
                        </span>
                      </h5> */}
                          <h5>
                            Total Boxes{" "}
                            <span>
                              {this.state.salesReturnData.reduce((sum, item) => sum + (item.quantity / (item.MOQ > 0 ? item.MOQ : 1)), 0)}
                            </span>
                          </h5>
                          <h5>
                            Total Quantity{" "}
                            <span>
                              {this.state.salesReturnData.reduce((sum, item) => sum + Number(item.quantity), 0)}
                            </span>
                          </h5>

                          <h5 className="grand-totall-title">
                            Total Amount{" "}
                            <span>
                              {this.state.salesReturnData.reduce((sum, item) => sum + (Number(item.wsp || item.amount) * Number(item.quantity)), 0)}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
          </>)}
        </div>
      </div>
    );
  }
}
AddSalesReturn.propTypes = {
  getActiveandInactiveCustomer: PropTypes.func.isRequired,
  getSalesBill: PropTypes.func.isRequired,
  getSalesUsingBarcode: PropTypes.func.isRequired,
  saleReturn: PropTypes.func.isRequired,
  deleteSale: PropTypes.func.isRequired,
  deletesaleReturn: PropTypes.func.isRequired,
  deletesaleReturnDefected: PropTypes.func.isRequired,
  checkLastFiveBills: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  customers: state.customerData.activeandInactiveCustomer,
  salesBillsUser: state.saleData.salesBillsUser,
  salesBillsBarcode: state.saleData.salesBillsBarcode,
  saleReturnDefected: state.saleData.salesBillsBarcode,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  lastFiveBills: state.saleData.lastFiveBills,
  brands: state.brandsData.brands,

});

export default connect(mapStateToProps, {
  getActiveandInactiveCustomer,
  getBrands,
  getSalesBill,
  getSalesUsingBarcode,
  saleReturn,
  saleReturnDefected,
  deletesaleReturn,
  deletesaleReturnDefected,
  checkLastFiveBills

})(AddSalesReturn);